import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaTruck } from "react-icons/fa";
import { FiCalendar, FiMap, FiUser } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../layout/Layout";
import {
  viewPunishment,
  deletePunishment,
  singlePunishment
} from "../../states/actions/punishmentAction";

const SinglePunishment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const punishments = useSelector((state) => state.punishment);
  console.log("pun==",punishments.punishment)
  // use effect
  useEffect(() => {
    dispatch(singlePunishment(id));
  }, []);

  const date = new Date();
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Typography variant="h5">Punishment details</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ my: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <FiCalendar />
                <Typography variant="h6">Jane 2, 2024</Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FaTruck />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Bus Info
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Bus Talga:{" "}
                    <span style={{ opacity: 0.7 }}>{punishments?.punishment[0]?.Bus.talga}</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FiUser />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Driver
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Name: <span style={{ opacity: 0.7 }}>{punishments?.punishment[0]?.Driver?.name}</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FiUser />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Bus Owner
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}>
                    Bus-Owner: <span style={{ opacity: 0.7 }}>{punishments?.punishment[0]?.Bus?.ownerId}</span>
                  </Typography>
                </Box>
              </Box>
             </Grid>
            <Grid item xs={12} sm={4}>
            <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FiUser />
                  </IconButton>
                </Box>
               <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Association
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}>
                  association: <span style={{ opacity: 0.7 }}>{punishments?.punishment[0]?.Bus?.associationId}</span>
                  </Typography>
                </Box>
                </Box>
             </Grid>
          </Grid>
        </Paper>
      </Box>
    </Layout>
  );
};

export default SinglePunishment;
