import { Avatar, Divider, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Profile = () => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  console.log("token data :", userInfo?.id);
  return (
    <Box>
      <Typography variant="subtitle1">Profile</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.8, pb: 2 }}>
        Update your photo and profile here
      </Typography>
      <Divider />
      <Box sx={{ mt: 3 }}>
        <Typography variant="subtitle1">Profile image</Typography>
        <Avatar src="/images/avatars/profile-avatar.png" />
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="First Name"
            variant="outlined"
            rows={4}
            fullWidth
            size="small"
            defaultValue={"abebe"}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            rows={4}
            fullWidth
            size="small"
            defaultValue={"kebie"}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Username"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={"beki man"}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Email"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={"kebie27@gmail.com"}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Phone"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={"+259 35789067"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
