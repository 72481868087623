import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { viewDriver } from "../../states/actions/driverAction";
import { viewAssociation } from "../../states/actions/associationAction";
import { viewBusOwner } from "../../states/actions/busOwnerAction";

import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";

import {
  updatePunishment,
  viewPunishment,
  singlePunishment,
} from "../../states/actions/punishmentAction";
import { viewBus } from "../../states/actions/busAction";
import Layout from "../../pages/layout/Layout";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const UpdatePunishment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate=useNavigate()
  useEffect(() => {
    onReset();
  }, []);
  const [userId, setUserId] = useState('');

  const onReset = async () => {
    const result = await dispatch(singlePunishment(id));
    if(result.payload.driverId){
      setUserId("driverId");
    }
    else if(result.payload.associationId)
    {
      setUserId("associationId");
    }
    else
    {
      setUserId("busOwnerId");
    }
  reset(result.payload);
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm();
  const busId=watch('busId')
  const selectedType = watch('type'); 
  const selectedLevel = watch('level');

  const buses = useSelector((state) => state.buses);

  // [{id:1,talga:"123",driverId:1,associationId:1,busOwnerId:1,driver:{driverId:1,name:"abebe"},association:{associationId:1,assoname:"toyota"},busOwner:{busOwnerId:1,onerName:"lemlem"}},
  // {id:2,driverId:2,talga:"234",associationId:2,busOwnerId:2,driver:{driverId:2,name:"gg"},association:{associationId:2,assoname:"dd"},busOwner:{busOwnerId:2,onerName:"ff"}},
  // {id:3,talga:"456",driverId:3,associationId:3,busOwnerId:1,driver:{driverId:3,name:"rr"},association:{associationId:3,assoname:"gg"},busOwner:{busOwnerId:1,onerName:"ff"}}]
  const driver = useSelector((state) => state.driver);
  // [{id:1,contactNumber:"093652627",frequency:1},{id:1,contactNumber:"093652627",frequency:1},{id:1,contactNumber:"093652627",frequency:1},{id:2,contactNumber:"846433",frequency:4},{id:3,contactNumber:"635435",frequency:3}]
  const association = useSelector((state) => state.association);
  const busOwners = useSelector((state) => state.busOwner);
  const punishments = useSelector((state) => state.punishment);
  const itemList=[]
  if(busId!=="")
    {
      for (let i=0;i<buses?.buses?.length;i++){
        if(buses?.buses[i]?.id==busId){   
          itemList.push({driverId:buses?.buses[i]?.driverId},{busOwnerId:buses?.buses[i]?.busOwnerId},{associationId:buses?.buses[i]?.associationId})
        }
      }
      //  dispatch(singleBus(busId));
      //  itemList.push({driver:buses?.buses?.driverId},{busOwner:buses?.buses?.busOwnerId},{associationId:buses?.buses?.associationId})
      };

    const ownerData=[]
    for (let i=0;i<busOwners?.busOwner?.length;i++){
        ownerData.push(busOwners.busOwner[i])
        }

    var driverId=itemList[0]?.driverId;
    let driverLength=0
    const selectDriver =()=>{
      for (let i=0;i<punishments?.punishment?.length;i++){
        if(punishments?.punishment[i]?.Driver?.id==driverId){    
          // alert(driver[i]?.frequency)
          driverLength++
          }
          }
        }
    // alert(driverId)
    var associationId=itemList[0]?.associationId;
    var associationLength=0;
    const selectAssociation =()=>{
    for (let i=0;i<punishments?.punishment?.length;i++){
    if(punishments?.punishment[i]?.Association?.associationId==associationId){
      associationLength++
    }
  }
  }
  var ownerId=itemList[0]?.busOwnerId;
  var busOwnerLength=0;
      const selectBOwner =()=>{
      for (let i=0;i<punishments?.punishment?.length;i++){
      if(punishments?.punishment[i]?.BusOwner?.ownerId==ownerId){
        busOwnerLength++
      }
      }}
    // use effect
    useEffect(() => {
      dispatch(viewBus());
      dispatch(viewDriver());
      dispatch(viewAssociation());
      dispatch(viewBusOwner());
      dispatch(viewPunishment());
      dispatch(updatePunishment());
    }, []);

    const onSubmit = (punishmentData) => {
    var amountValue;
    var frequencyValue;

    const formData = new FormData();
      Object.entries(punishmentData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (selectedType=="Driver Punishment")
      {      
      if(punishmentData.level=="level-1") {
        amountValue=300
        frequencyValue=driverLength
      } 
      else if (punishmentData.level=="level-2") {
        amountValue=600
        frequencyValue=driverLength
      } 
      else if(punishmentData.level=="level-3") {
        amountValue=800
        frequencyValue=driverLength
        
      }

      else {
        amountValue=3000
        frequencyValue=driverLength
      }

      // selectTypValu=itemList[0]?.driverId
    }
  else if (selectedType=="Bus-Owner Punishment")
      {
    if (punishmentData.level=="level-1") {
        amountValue=300
        frequencyValue=busOwnerLength
      } 
      else if (punishmentData.level=="level-2") {
        amountValue=600
        frequencyValue=busOwnerLength
      } 
      else if (punishmentData.level=="level-3") {
        amountValue=800
        frequencyValue=busOwnerLength
      } 
      else {
        amountValue=3000
        frequencyValue=busOwnerLength
       }
       // selectTypValu=itemList[1]?.busOwnerId 
      }
   else if(selectedType=="Association Punishment")
      {
      if(punishmentData.level=="level-1") {
        amountValue=300
        frequencyValue=associationLength
      } 
      else if (punishmentData.level=="level-2") {
        amountValue=600
        frequencyValue=associationLength
      } 
      else if (punishmentData.level=="level-3") {
        amountValue=800
        frequencyValue=associationLength
      } 
      else {
        amountValue=3000
        frequencyValue=associationLength
      }
      // selectTypValu=itemList[2]?.associationId 
    }
  else
    {      
  alert("please, select punishment type")
  }

    const newData = {
      ...punishmentData,
      frequency:frequencyValue,
      amount:amountValue,
      driverId:itemList[0]?.driverId
    };
     console.log("today pun valu==",newData)
    // dispatch
    dispatch(updatePunishment(newData));
  };
  const { t: translate } = useTranslation(["punishment"]);
  return (
    <Layout>
    <Box class="py-24 h-screen">
      <div class="mx-auto container max-w-2xl md:w-3/4 shadow-md">
        <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
          <div class="max-w-sm mx-auto md:w-full md:mx-0">
            <div class="inline-flex items-center space-x-4">
              <Typography variant="h6">
                {" "}
                {translate("NewPunishment")}
              </Typography>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper>
            <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("busTalga")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("selectTalga")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        {...register(`busId`, {
                          maxLength: 7,
                        })}
                      >
                        {buses?.buses?.map((option) => (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.talga}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>
            </div>
            {/* <Divider />
            <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-gray-500 items-center">
              <Typography class="md:w-4/12 mx-auto max-w-sm">
                {translate("Amount")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box>
                    <TextField
                      label={translate("amountPlace")}
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`amount`, {
                        required: true,
                        maxLength: 20,
                      })}
                    />
                  </Box>
                  {errors.amount && (
                    <p className="text-red-500">
                      {translate("enterAmount")}!
                    </p>
                  )}
                </div>
              </div>
            </div> */}
            {busId && (
                <>
            <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("type")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  {/* <RadioGroup
                     variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      onChange={selectType()}
                      {...register(`type`, {
                        required: true,
                        maxLength: 700,
                      })}
                  >
                    <FormControlLabel value="driver" control={<Radio />} label="Driver" />
                    <FormControlLabel value="busOwner" control={<Radio />} label="Bus Owner" />
                    <FormControlLabel value="association" control={<Radio />} label="Association" />
                  </RadioGroup> */}
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("selectType")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`type`, {
                        maxLength: 700,
                      })}
                    >     
                     {/* <MenuItem value={itemList[0]?.driverId}>{itemList[0]?.driver}</MenuItem>
                     <MenuItem value={itemList[1]?.busOwnerId}>{itemList[1]?.busOwner}</MenuItem>
                     <MenuItem value={itemList[2]?.associationId}>{itemList[2]?.association}</MenuItem> */}

                      <MenuItem value='Driver Punishment'>{translate("driverDropDown")}</MenuItem> 
                      <MenuItem value='Bus-Owner Punishment'>{translate("Bus-Owner punishment")}</MenuItem>
                       <MenuItem value='Association Punishment'>{translate("Association punishment")}</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>
              </>
              )}
      
              {/* Based on type of punishment select the user phone */}
              { selectedType=="Driver Punishment"&& (
                <>
              <Divider />
            {/* <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("driverPhone")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("selectPhone")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        {...register(`driverId`, {
                          required: true,
                          maxLength: 100,
                        })}
                      >
                      <MenuItem value={itemList[0]?.driverId}>
                      {itemList[0]?.driverId}
                          </MenuItem>
                         {driver?.driver?.map((option) => (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.contactNumber}
                          </MenuItem>
                        ))} 
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                {errors.driverId && (
                  <p className="text-red-500">{translate("selectDriver")}!</p>
                )}
              </div>
            </div> */}

            <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Frequency")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      type="text"
                      onClick={selectDriver()}
                      value={driverLength}
                    />
                  </Box>
                </div>
              </div>
            </div>
            </>
         )}
         {selectedType=="Association Punishment" && (
                <>
                <Divider />
                {/* <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
              {translate("association")}</Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex ">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                      {translate("selectAssociation")}
                      </InputLabel>
                    <Select
                    variant="outlined"
                      size="small"
                      fullWidth
                      type="text" 
                      onClick={selectAssociation()}
                   {...register(`associationId`,{
                        required:true
                      })}
                    >
                      {association?.association?.map((association) => (
                        <MenuItem
                          key={association.associationId}
                          value={association.associationId}
                        >
                        {association.associationName}
                        </MenuItem>
                      ))}
                    </Select>
                    </FormControl>
                  </Box>
                    {errors.associationId && (
                    <p className="text-red-500">{translate("selectAssociation")}!</p>
                  )}
                </div>
              </div>
            </div> */}
            {/* {associationId && (
                <> */}
            <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Frequency")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={associationLength}
                      {...register(`frequency`)}
                      type="text"
                    />
                  </Box>
                </div>
              </div>
            </div>
              {/* </>
              )} */}
            </>
         )}
         {selectedType=="Bus-Owner Punishment" && (
                <>
                <Divider />
           {/* <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
             <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("BOwner")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex ">
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("selectBOwner")}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        onClick={selectBOwner()}
                        {...register(`busOwnerId`, {
                          required: true,
                        })}
                      >
                        {ownerData?.map((owner) => (
                          <MenuItem
                            key={owner?.busOwnerId}
                            value={owner?.busOwnerId}
                          >
                            {owner?.firstName}
                          </MenuItem>
                        ))} 
                      </Select>
                    </FormControl>
                  </Box>
                  {errors.busOwnerId && (
                    <p className="text-red-500">
                      {" "}
                      {translate("selectBOwner")}!
                    </p>
                  )}
                </div>
              </div>
            </div> */}
            {/* {ownerId && (
                <> */}
            <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Frequency")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      type="text"
                      value={busOwnerLength}
                    />
                  </Box>
                </div>
              </div>
            </div>
              {/* </>
              )} */}
            </>
         )}
             {/* Select level of punishment level here  */}
        <Divider />
        <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("level")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("selectLevel")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`level`, {
                        maxLength: 700,
                      })}
                    >                         
                      <MenuItem value="level-1">{translate("l1")}</MenuItem>
                      <MenuItem value="level-2">{translate("l2")}</MenuItem>
                      <MenuItem value="level-3">{translate("l3")}</MenuItem>
                      <MenuItem value="level-4">{translate("l4")}</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>
                              
              {/* After selecting type and level of punishment select the user punishment category description */}
              {( selectedType=='Bus-Owner Punishment' && selectedLevel=='level-1') && (
                <>
                <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Description")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("Description")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`description`, {
                        maxLength: 1350,
                      })}
                    >                         
                      <MenuItem value="የህዝብ ማመላለሻ ተሽከርካሪ ያለ ህጋዊ ፈቃድ ከስምሪት አገልግሎት ውጭ ማድረግ /መደበቅ/">1</MenuItem>
                      <MenuItem value="የአኦፕሬተርነት ፍቃድ በተሰጠው ውቅት ተሙአልታዋል ተብለው የተገለጹት በቁጥጥር ውቅት አጉአድሎ መገኘት">2</MenuItem>
                      <MenuItem value="በተዘጋጁ ቅጾች መሰረት የሚዘጋጁ መረጃዎች በአግባቡና በወቅቱ ጠብቆ አለማስተላለፍ">3</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>
            </>
         )}
         {( selectedType=='Bus-Owner Punishment' && selectedLevel=='level-2') && (
                <>
                <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Description")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("Description")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`description`, {
                        maxLength: 1350,
                      })}
                     >                         
                      <MenuItem value="ተሽከርካሪ ሳይቆም በር መክፈት">1</MenuItem>
                      <MenuItem value="ተሽከርካሪ ሳይቆም ሰው ማውረድ(ማሳፍር)">2</MenuItem>
                      <MenuItem value="የደንብ ልብሱን ባግባቡ አለመልበስ">3</MenuItem>
                      <MenuItem value="ህጋዊ መታዎቂያና ባጅ ይዞ አለመገኘት">4</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>
            </>
         )}
         {( selectedType=='Bus-Owner Punishment' && selectedLevel=='level-3') && (
                <>
                <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Description")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("Description")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`description`, {
                        maxLength: 1350,
                      })}
                    >                         
                      <MenuItem value="የተሳፋሪዎችን ንብረት ባያያዝ ጉድለት እንዲሰበር ወይም እንዲበላሽ ማድረግ">1</MenuItem>
                      <MenuItem value="መረጃ ሲጠየቅ ለመስጠት ፍቃደኛ አለለመሆን">2</MenuItem>
                      <MenuItem value="ተሳፋሪውን ማንገላታት፤መዘለፍና ማንጉአጠጥ">3</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>

            </>
         )}
        {/* After selecting type and level of punishment select the user punishment category description association*/}
        {( selectedType=='Driver Punishment' && selectedLevel=='level-1') && (
                <>
                <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Description")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("Description")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`description`, {
                        maxLength: 1350,
                      })}
                    >                         
                      <MenuItem value="የህዝብ ማመላለሻ ተሽከርካሪ ያለ ህጋዊ ፈቃድ ከስምሪት አገልግሎት ውጭ ማድረግ /መደበቅ/">1</MenuItem>
                      <MenuItem value="የአኦፕሬተርነት ፍቃድ በተሰጠው ውቅት ተሙአልታዋል ተብለው የተገለጹት በቁጥጥር ውቅት አጉአድሎ መገኘት">2</MenuItem>
                      <MenuItem value="በተዘጋጁ ቅጾች መሰረት የሚዘጋጁ መረጃዎች በአግባቡና በወቅቱ ጠብቆ አለማስተላለፍ">3</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>
            </>
         )}
         {( selectedType=='Driver Punishment' && selectedLevel=='level-2') && (
                <>
                <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Description")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("Description")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`description`, {
                        maxLength: 1350,
                      })}
                    >                         
                      <MenuItem value="ተሽከርካሪ ሳይቆም በር መክፈት">1</MenuItem>
                      <MenuItem value="ተሽከርካሪ ሳይቆም ሰው ማውረድ(ማሳፍር)">2</MenuItem>
                      <MenuItem value="የደንብ ልብሱን ባግባቡ አለመልበስ">3</MenuItem>
                      <MenuItem value="ህጋዊ መታዎቂያና ባጅ ይዞ አለመገኘት">4</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>

            </>
         )}
         {( selectedType=='Driver Punishment' && selectedLevel=='level-3') && (
                <>
                <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Description")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("Description")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`description`, {
                        maxLength: 1350,
                      })}
                    >                         
                      <MenuItem value="የተሳፋሪዎችን ንብረት ባያያዝ ጉድለት እንዲሰበር ወይም እንዲበላሽ ማድረግ">1</MenuItem>
                      <MenuItem value="መረጃ ሲጠየቅ ለመስጠት ፍቃደኛ አለለመሆን">2</MenuItem>
                      <MenuItem value="ተሳፋሪውን ማንገላታት፤መዘለፍና ማንጉአጠጥ">3</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>

            </>
         )}
        {/* After selecting type and level of punishment select the user punishment category description of Bus-Owner */}
        {( selectedType=='Association Punishment' && selectedLevel=='level-1') && (
                <>
                <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Description")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("Description")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`description`, {
                        maxLength: 1350,
                      })}
                    >                         
                      <MenuItem value="የህዝብ ማመላለሻ ተሽከርካሪ ያለ ህጋዊ ፈቃድ ከስምሪት አገልግሎት ውጭ ማድረግ /መደበቅ/">1</MenuItem>
                      <MenuItem value="የአኦፕሬተርነት ፍቃድ በተሰጠው ውቅት ተሙአልታዋል ተብለው የተገለጹት በቁጥጥር ውቅት አጉአድሎ መገኘት">2</MenuItem>
                      <MenuItem value="በተዘጋጁ ቅጾች መሰረት የሚዘጋጁ መረጃዎች በአግባቡና በወቅቱ ጠብቆ አለማስተላለፍ">3</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>

            </>
         )}
         {( selectedType=='Association Punishment' && selectedLevel=='level-2') && (
                <>
                <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Description")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("Description")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`description`, {
                        maxLength: 1350,
                      })}
                    >                         
                      <MenuItem value="ተሽከርካሪ ሳይቆም በር መክፈት">1</MenuItem>
                      <MenuItem value="ተሽከርካሪ ሳይቆም ሰው ማውረድ(ማሳፍር)">2</MenuItem>
                      <MenuItem value="የደንብ ልብሱን ባግባቡ አለመልበስ">3</MenuItem>
                      <MenuItem value="ህጋዊ መታዎቂያና ባጅ ይዞ አለመገኘት">4</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>

            </>
         )}
         {( selectedType=='Association Punishment' && selectedLevel=='level-3') && (
                <>
                <Divider />
            <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Description")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {translate("Description")}
                      </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`description`, {
                        maxLength: 1350,
                      })}
                    >                         
                      <MenuItem value="የተሳፋሪዎችን ንብረት ባያያዝ ጉድለት እንዲሰበር ወይም እንዲበላሽ ማድረግ">1</MenuItem>
                      <MenuItem value="መረጃ ሲጠየቅ ለመስጠት ፍቃደኛ አለለመሆን">2</MenuItem>
                      <MenuItem value="ተሳፋሪውን ማንገላታት፤መዘለፍና ማንጉአጠጥ">3</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                  </div>
                </div>
              </div>

            </>
         )}

              
              {/* <Divider />
            <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-gray-500 items-center">
              <Typography class="md:w-4/12 mx-auto max-w-sm">
                {translate("Frequency")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box>
                    <TextField
                      label={translate("FrequencyPlace")}
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      {...register(`frequency`, {
                        required: true,
                        maxLength: 20,
                      })}
                    />
                  </Box>
                  {errors.frequency && (
                    <p className="text-red-500">
                      {translate("enterFrequency")}!
                    </p>
                  )}
                </div>
              </div>
            </div> */}
            <Divider />
          <div class="md:inline-flex w-full space-y-4 md:space-y-0 p-8 text-gray-500 items-center">
            <Typography class="md:w-4/12 max-w-sm mx-auto">
              {translate("PunishmentDate")}
            </Typography>
            <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
              <div>
                <Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="text"
                    {...register(`date`, {
                      maxLength: 100,
                    })}
                  />
                </Box>
              </div>
            </div>
          </div>
            {/* <Divider />
            <div class="md:inline-flex w-full space-y-4 md:space-y-0 p-8 text-gray-500 items-center">
              <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("Description")}
              </Typography>
              <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                <div>
                  <Box>
                    <TextField
                      label={translate("punishmentdescription")}
                      variant="outlined"
                      rows={2}
                      fullWidth
                      multiline
                      type="text"
                      {...register(`description`, {
                        required: true,
                        maxLength: 700,
                      })}
                    />
                  </Box>
                  {errors.description && (
                    <p className="text-red-500">
                      {translate("enterDescription")}!
                    </p>
                  )}
                </div>
              </div>
            </div> */}
            <div class="w-full pb-2 text-right text-gray-500">
              <button className="md:w-2/3 focus:outline-none mr-4 bg-blue-400 hover:bg-indigo-500 text-white font-bold xl:text-base md:text-base border border-gray-300 w-full text-sm py-2.5 outline-blue-500 rounded">
                {translate("button1")}
              </button>
            </div>
          </Paper>
        </form>
      </div>
    </Box>
    <ToastContainer />
  </Layout>
  );
};
export default UpdatePunishment;
