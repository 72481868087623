import React, { useEffect,useState } from "react";
import { Box, Button,Paper, Typography } from "@mui/material";
import Layout from "../../pages/layout/Layout";
import { viewRoutes} from "../../states/actions/routeAction";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import Loading from "../loading/Loading";
import { useTranslation } from "react-i18next";

const TarifReport = () => {
    // dispatch

    // hooks
    const routes = useSelector((state) => state.routes);
    console.log(routes?.routes)
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(viewRoutes());
    }, []);
    const { t: translate } = useTranslation(["tarif"]);
    const headers = [
      { label: translate("source"),key: "Source"},
      { label: translate("description"), key: "Destination"},
      { label: translate("grave"), key: "grave"},
      { label: translate("asphalt"), key: "asphalt"},
      { label: translate("sum"), key: "distance"},
      { label: translate("l1"), key: "smallL1"},
      { label: translate("l2"), key: "smallL2"},
      { label: translate("l3"), key: "smallL3"},
      { label:  translate("l1"), key: "milL1"},
      { label: translate("l2"), key: "milL2"},
      { label: translate("l3"), key: "milL3"},
      ];
      let value=[];
      for (let i=0;i<routes?.routes?.length;i++){
       value.push({Source: routes?.routes[i]?.sourceStation.stationName,
                   Destination: routes?.routes[i]?.destinationStation.stationName,
                   grave:routes?.routes[i]?.graveDistance,
                   asphalt:routes?.routes[i]?.asphaltDistance,
                  distance: parseFloat(routes?.routes[i].graveDistance) + parseFloat(routes?.routes[i].asphaltDistance),
                  smallL1:(((parseFloat(routes?.routes[i].graveDistance))*1.023) + ((parseFloat(routes?.routes[i].asphaltDistance))*0.9992)).toFixed(2),
                  smallL2:((parseFloat(routes?.routes[i].graveDistance))*0.9859 + parseFloat(routes?.routes[i].asphaltDistance)*0.9785).toFixed(2),
                  smallL3:((parseFloat(routes?.routes[i].graveDistance))*0.9736 + (parseFloat(routes?.routes[i].asphaltDistance))*0.9674).toFixed(2),
                  milL1:((parseFloat(routes?.routes[i].graveDistance))*0.9736 + (parseFloat(routes?.routes[i].asphaltDistance))*1.0125).toFixed(2),
                  milL2:((parseFloat(routes?.routes[i].graveDistance))*0.965 + (parseFloat(routes?.routes[i].asphaltDistance))*0.93).toFixed(2),
                  milL3:((parseFloat(routes?.routes[i].graveDistance))*0.8923 + (parseFloat(routes?.routes[i].asphaltDistance))*0.8738).toFixed(2)
                   })}
      const csvReport = {
        data: value,
        headers: headers,
        filename: 'Clue_Mediator_Report.csv'
        };
        // console.log("All Route: ", routes);
    // use effect
  return (
    <Layout>
    <Box className=" py-24 px-8 h-screen pr-2">
    <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h7">{translate("header")}</Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: "5px" }}
            >
            <CSVLink {...csvReport}>{translate("print")}</CSVLink>
            </Button>
        </Box>
    <div className="w-full overflow-x-auto">
    {/* <ThemeProvider theme={theme}> */}
    <table className=" rounded-2xl">
    <Paper className=" space-y-2 shadow border-2 rounded-md w-full">
        <thead>
          <tr>
          <th colSpan={12} className="p-1">በአብክመ ትራንስፖርት ሎጂስቲክስ ባለስልጣንና የኢፌድሪ የንገድ ቀጠናዊ ትስስር ሚኒስቴር ከጥር 1/2015 ዓ/ም እስከ ጥር 30/2015 ዓ/ም
           ድረስ የሚቆይ የነዳጅ ምርቶች የችርቻሮ ዋጋ ክለሳን ምክንያት በማድረግ የተሻሻለ የህዝብ ትራንስፖርት ታሪፍ
          </th>
          </tr>
          <tr>
            <th className="py-2 px-4 border-b border-r border-l border-t">{translate("no")}</th>
            <th colSpan={2} className="py-2 px-4 border-b border-r border-t">{translate("city")}</th>
            <th colSpan={3} className="py-2 px-4 border-b border-r border-t">{translate("distance")}</th>
            <th colSpan={3} className="py-2 px-4 border-b border-r border-t">{translate("small")}</th>
            <th colSpan={3} className="py-2 px-4 border-b border-r border-t">{translate("mil")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
           <th className="py-2 px-4 border-b border-r border-l"> </th>
            <th className="py-2 px-4 border-b border-r"> </th>
            <th className="py-2 px-4 border-b border-r"> </th>
            <th className="py-2 px-4 border-b border-r"> </th>
            <th className="py-2 px-4 border-b border-r"> </th>
            <th className="py-2 px-4 border-b border-r"> </th>
            <th className="py-2 px-4 border-b border-r">{translate("l1")}</th>
            <th className="py-2 px-4 border-b border-r">{translate("l2")}</th>
            <th className="py-2 px-4 border-b border-r">{translate("l3")}</th>
            <th className="py-2 px-4 border-b border-r">{translate("l1")}</th>
            <th className="py-2 px-4 border-b border-r">{translate("l2")}</th>
            <th className="py-2 px-4 border-b border-r">{translate("l3")}</th>
          </tr>
          <tr>
           <th className="py-2 px-4 border-b border-r border-l"> </th>
            <th className="py-2 px-4 border-b border-r">{translate("source")}</th>
            <th className="py-2 px-4 border-b border-r">{translate("destination")}</th>
            <th className="py-2 px-4 border-b border-r">{translate("grave")}</th>
            <th className="py-2 px-4 border-b border-r">{translate("asphalt")}</th>
            <th className="py-2 px-4 border-b border-r">{translate("sum")}</th>
            <th className="py-2 px-4 border-b border-r">0.9992</th>
            <th className="py-2 px-4 border-b border-r">0.9785</th>
            <th className="py-2 px-4 border-b border-r">0.9674</th>
            <th className="py-2 px-4 border-b border-r">0.9787</th>
            <th className="py-2 px-4 border-b border-r">0.93</th>
            <th className="py-2 px-4 border-b border-r">0.8738</th>
          </tr>
          {
          (routes?.routes?.length)>0
            ?(
            routes?.routes?.map((values,index)=>{
              return(
           <>
            <tr key={index}>
             <td className="py-2 px-4 border-b border-r border-l">{index+1}</td>
             <td className="py-2 px-4 border-b border-r">{values.sourceStation.stationName}</td>
             <td className="py-2 px-4 border-b border-r">{values.destinationStation.stationName}</td>
             <td className="py-2 px-4 border-b border-r">{values.graveDistance}</td>
             <td className="py-2 px-4 border-b border-r">{values.asphaltDistance}</td>
             <td className="py-2 px-4 border-b border-r">{(parseFloat(values.graveDistance) + parseFloat(values.asphaltDistance)).toFixed(2)}</td>
             <td className="py-2 px-4 border-b border-r">{(((parseFloat(values.graveDistance))*1.023) + ((parseFloat(values.asphaltDistance))*0.9992)).toFixed(2)}</td>
             <td className="py-2 px-4 border-b border-r">{((parseFloat(values.graveDistance))*0.9859 + parseFloat(values.asphaltDistance)*0.9785).toFixed(2)}</td>
             <td className="py-2 px-4 border-b border-r">{((parseFloat(values.graveDistance))*0.9736 + (parseFloat(values.asphaltDistance))*0.9674).toFixed(2)}</td>
             <td className="py-2 px-4 border-b border-r">{((parseFloat(values.graveDistance))*0.9736 + (parseFloat(values.asphaltDistance))*1.0125).toFixed(2)}</td>
             <td className="py-2 px-4 border-b border-r">{((parseFloat(values.graveDistance))*0.965 + (parseFloat(values.asphaltDistance))*0.93).toFixed(2)}</td>
             <td className="py-2 px-4 border-b border-r">{((parseFloat(values.graveDistance))*0.8923 + (parseFloat(values.asphaltDistance))*0.8738).toFixed(2)}</td>
           </tr>
           </>
            )})) : (
          <Loading />
        )}
       </tbody>
       </Paper>
      </table>
    </div>
     {/* </ThemeProvider> */}
     </Box>
  </Layout>
  );
};
export default TarifReport;