import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import DetailModal from '../../../components/modal/DetailModal';
import {
  viewMaintenanceSchedule,
  deleteMaintenanceHistory,
  singleMaintenanceSchedule
} from "../../../states/actions/maintenanceAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Table from "../../../components/Table";
import { ToastContainer } from "react-toastify";
import Layout from "../../../pages/layout/Layout";
import Loading from "../../loading/Loading";
import { FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const ViewMaintenace = () => {
  // dispatch
  const dispatch = useDispatch();
  // hooks
  const maintenance = useSelector((state) => state.maintenance);
  // use effect
  useEffect(() => {
    dispatch(viewMaintenanceSchedule());
  }, []);
  const navigate = useNavigate();

  const handleUpdateMaintenance = (data) => {
    navigate("/maintenance/" + data.maintenanceId);
  };
  function handleRemoveMaintenance(data) {
    //console.log("id : ", id);
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deleteMaintenanceHistory(data.maintenanceId));
    }
    setTimeout(function () {
      window.location.reload();
    }, 500);
    //  console.log("handle ondelet true");
  }
          //  Popup data
          const [isOpen, setIsOpen] = useState(false);
          const header= ["description","cost","bus","createdAt","updatedAt"] 
          const [newValue, setNewValue] = useState([]);
          const openModal = async(data) => {
            const result = await dispatch(singleMaintenanceSchedule(data.maintenanceId));    
            const value= [result.payload?.description,result.payload?.cost,result.payload?.Bus.talga,result.payload?.createdAt,result.payload?.updatedAt] 
            const newArray = [...newValue]; 
                  newArray[0] = value[0]; 
                  newArray[1] = value[1]; 
                  newArray[2] = value[2]; 
                  newArray[3] = value[3]; 
                  newArray[4] = value[4]; 
                  newArray[5] = value[5]; 
              setNewValue(newArray);
              if(newArray.length>0) {
                setIsOpen(true);  
                }               
             };
             const closeModal = () => {
              setIsOpen(false);
              setNewValue([])
              dispatch(viewMaintenanceSchedule());
             }
 const { t: translate } = useTranslation(["maintenance/viewMaintenance"]);
  const busMaintenanceColumns = [
  {
    accessorKey: "Bus.talga", //access nested data with dot notation
    header: translate("talga"),
  },
  {
    accessorKey: "Bus.sideNo", //access nested data with dot notation
    header: translate("sideNo"),
  },
  {
    accessorKey: "cost", //access nested data with dot notation
    header: translate("cost"),
  },
  {
    accessorKey: "description", //access nested data with dot notation
    header: translate("description"),
  },
];
  return (
    <Layout>
      <Box sx={{ pt: "10px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Link to="/addMaintenance" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
              {translate("add")}
            </Button>
          </Link>
        </Box>
        {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="maintenanceTitle" headr={header} value={newValue} />}

        {maintenance?.maintenance ? (
          <Table
            data={maintenance?.maintenance}
            fields={busMaintenanceColumns}
            numberOfRows={maintenance?.maintenance.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            //  routeLink="products"
            deleteFunction={handleRemoveMaintenance}
            upDatefunction={handleUpdateMaintenance}
            viewFunction={openModal}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default ViewMaintenace;


