import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaTruck } from "react-icons/fa";
import { FiCalendar, FiMap, FiUser } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../layout/Layout";
import { singleTicketing } from "../../states/actions/ticketingAction";

const SingleTicketing = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(singleTicketing(id));
  }, []);
  const { tickets } = useSelector((state) => state.tickets);
  // x = tickets?.Route?.cost;
  var cost = parseInt(tickets?.Route?.cost, 10);

  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Typography variant="h5">Ticketing details</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ my: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <FiCalendar />
                <Typography variant="h6">January 1, 2021</Typography>
              </Box>
              <Typography variant="subtitle2" sx={{ opacity: 0.7, pb: 1 }}>
                Ticket ID #038
              </Typography>
            </Box>
            <Box>
              <span
                style={{
                  padding: "3px 10px",
                  color: "#049bf5",
                  backgroundColor: "rgba(4, 155, 245,0.2)",
                  borderRadius: "5px",
                  textDecoration: "none",
                }}
              >
                success
              </span>
            </Box>
          </Box>
          <Divider />
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FiUser />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Passenger
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Name:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {tickets?.Passenger?.name}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Phone:{" "}
                    <span style={{ opacity: 0.7 }}>+2519 785 643 56</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Reservation Date:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {tickets?.Passenger?.reservationDate}
                    </span>
                  </Typography>
                  <Link
                    to={`/singlePassenger/${tickets?.Passenger?.id}`}
                    style={{
                      padding: "3px 10px",
                      color: "#049bf5",
                      backgroundColor: "rgba(4, 155, 245,0.2)",
                      borderRadius: "5px",
                      textDecoration: "none",
                    }}
                  >
                    View Profile
                  </Link>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FaTruck />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Bus Info
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    licensePlate:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {tickets?.Bus?.licensePlate}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Side Number:{" "}
                    <span style={{ opacity: 0.7 }}>{tickets?.Bus?.sideNo}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Talga:{" "}
                    <span style={{ opacity: 0.7 }}>{tickets?.Bus?.talga}</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FiMap />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Routes
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Source Station:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {tickets?.Route?.sourceStation?.stationName}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Destination Station:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {tickets?.Route?.destinationStation?.stationName}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2, opacity: 0.7 }}
                  >
                    {tickets?.Route?.distance} km
                  </Typography>

                  <Link
                    to="/viewTracking"
                    style={{
                      padding: "3px 10px",
                      color: "#049bf5",
                      backgroundColor: "rgba(4, 155, 245,0.2)",
                      borderRadius: "5px",
                      textDecoration: "none",
                    }}
                  >
                    View on Map
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* <Typography variant="h6" sx={{ mt: 2 }}>
            Products details
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Table
              data={orderDetails}
              fields={orderDetailsColumns}
              numberOfRows={orderDetails.length}
              enableTopToolBar={false}
              enableBottomToolBar={false}
              enablePagination={false}
              enableRowSelection={false}
              enableColumnFilters={false}
              enableEditing={false}
              enableColumnDragging={false}
            />
          </Box> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 2,
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                  width: "200px",
                }}
              >
                <Typography variant="subtitle2">Subtotal</Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                  {tickets?.Route?.cost} birr
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                  width: "200px",
                }}
              >
                <Typography variant="subtitle2">Tax(20%)</Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                  2.5 birr
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                  width: "200px",
                }}
              >
                <Typography variant="subtitle2">Discount(10%)</Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                  3.50
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                  width: "200px",
                }}
              >
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6" sx={{ opacity: 0.8 }}>
                  {cost + 3.5 + 2.5}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Layout>
  );
};

export default SingleTicketing;
