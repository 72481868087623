import styled from "@emotion/styled";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
const BaseStats = (props) => {
  const { t: translate } = useTranslation(["assignation/addAssignation"]);
  return (
    <>
      <Box
        sx={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
        }}
        className=" md:w-10/12 w-full h-full flex rounded-xl text-right justify-center"
      >
        <Paper className=" space-y-2 shadow flex flex-col w-full text-right rounded-2xl justify-center ">
          {/* <LineChart
           chartOptions={lineChartOptions}
           chartData={lineChartData}
         /> */}
          {/* <div>
            <img
              src={props.image}
              alt=""
              className="rounded-md w-full h-full mt-0 bg-slate-300 border-2 border-slate-400"
            />
          </div> */}
          <div className=" h-full w-full  rounded-xl flex">
            <div className=" w-1/2">
            <img
              src={props.image}
              alt=""
              className="rounded-md w-full h-full mt-0"
            />
            </div>
            <div className=" w-1/2 text-right justify-center pr-7 pt-7">
            <Typography
              variant="h8"
              className=" text-5xl font-bold pt-3 text-orange-400"
            >
              {props.totalNo}
            </Typography>
            <Typography className="font-semibold p-1 m-1 ml-4 mt-4 text-2xl text-green-500 ">
              {props.name}
            </Typography>
              <button class=" md:p-3 p-1 pt-2 pb-2 m-1 rounded-md mb-7 text-sky-500 mt-3  text-sm animate-pulse ">
              <Typography>
              <Link to={props.link}  >
             {translate("View All")} 
             </Link>
              </Typography>
              </button>
           </div>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default BaseStats;
