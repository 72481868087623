import { Box, Typography,Button } from "@mui/material";
import React, { useEffect,useState } from "react";
import Table from "../../components/Table";
import { viewStation, deleteStation,singleStation } from "../../states/actions/stationAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate ,Link} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../../pages/layout/Layout";
import Loading from "../loading/Loading";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import DetailModal from '../../components/modal/DetailModal';

const AllStation = () => {
  // dispatch
  const dispatch = useDispatch();
  // useSelectors
  const stations = useSelector((state) => state.stations);
  // use effect
  useEffect(() => {
    dispatch(viewStation());
  }, []);

  const navigate = useNavigate();
  //update passenger funcion
  const handleUpdateStation = (data) => {
    navigate("/station/" + data.stationId);
  };
  //delete passenger funcion
  function handleRemoveStation(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deleteStation(data.stationId));
    }
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }
            //  Popup data
            const [isOpen, setIsOpen] = useState(false);
            const header= ["station","location","contactPerName","personalEmail","contactNo","createdAt","updatedAt"] 
            const [newValue, setNewValue] = useState([]);
            const openModal = async(data) => {
              const result = await dispatch(singleStation(data.stationId));     
              const value= [result.payload?.stationName,result.payload?.location,result.payload?.contactPersonName,result.payload?.contactPersonEmail,
                result.payload?.contactPersonPhone,result.payload?.createdAt,result.payload?.updatedAt] 
              const newArray = [...newValue]; 
                    newArray[0] = value[0]; 
                    newArray[1] = value[1]; 
                    newArray[2] = value[2]; 
                    newArray[3] = value[3]; 
                    newArray[4] = value[4]; 
                    newArray[5] = value[5]; 
                    newArray[6] = value[6]; 
                setNewValue(newArray);
                if(newArray.length>0) {
                  setIsOpen(true);  
                  }
               };
               const closeModal = () => {
                setIsOpen(false);
                setNewValue([])
                dispatch(viewStation());
               }
        const { t: translate } = useTranslation(["station/viewStation"]);
        const stationColumns = [
        {
          accessorKey: "stationName",
          header: translate("name"),
        },
        {
          accessorKey: "location",
          header: translate("location"),
        },
        {
          accessorKey: "contactPersonName",
          header: translate("contactName"),
        },
        {
          accessorKey: "contactPersonEmail",
          header: translate("contactPhone"),
        },
        {
          accessorKey: "contactPersonPhone",
          header: translate("contactEmail"),
        },
      ];
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Link to="/NewStation" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
            {translate("add")}
            </Button>
          </Link>
        </Box>
        {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="stationTitle" headr={header} value={newValue} />}

        {stations?.stations ? (
          <Table
            data={stations?.stations}
            fields={stationColumns}
            numberOfRows={stations?.stations?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            deleteFunction={handleRemoveStation}
            upDatefunction={handleUpdateStation}
            viewFunction={openModal}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default AllStation;


