import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { addItem } from "../../states/actions/lostItemAction";
import { newTicketing } from "../../states/actions/ticketingAction";
import Datepicker from "react-tailwindcss-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { viewBus } from "../../states/actions/busAction";
import { viewRoutes } from "../../states/actions/routeAction";
import moment from "moment/moment";
import Layout from "../../pages/layout/Layout";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

const NewTicketing = () => {
  // use form
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (ticketData) => {
    // pass the data form data
    const formData = new FormData();
    Object.entries(ticketData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    let newDate = moment(ticketData).format("yyyy-MM-DD");
    const newData = {
      ...ticketData,
      passengerId: "1",
      reservationDate: newDate,
    };
    // dispatch
    dispatch(newTicketing(newData));
    reset();
    setTimeout(function () {
      navigate("/allTicketing");
    }, 5000);
  };
  const buses = useSelector((state) => state.buses);
  // use effect
  useEffect(() => {
    dispatch(viewBus());
  }, []);
  const routes = useSelector((state) => state.routes);
  // use effect
  useEffect(() => {
    dispatch(viewRoutes());
  }, []);

  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

    const { t: translate } = useTranslation(["ticketing/addTicketing"]);

  return (
    <Layout>
      <Box className="py-24 h-screen">
        <div class="mx-auto container max-w-2xl md:w-3/4 shadow-md">
          <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
            <div class="max-w-sm mx-auto md:w-full md:mx-0">
              <div class="inline-flex items-center space-x-4">
                <Typography variant="h6">{translate("header")}</Typography>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className=" space-y-2 shadow">
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {" "}
                {translate("bus")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                        {translate("selectBus")}
                        </InputLabel>
                        <Select
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="text"
                          {...register(`busId`, {
                            required: true,
                            maxLength: 7,
                          })}
                        >
                          {buses?.buses?.map((item) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item?.talga}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  {errors.busId && (
                    <p className="text-red-500"> {translate("selectBus")}!</p>
                  )}
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                {translate("route")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                        {translate("selectRoute")}
                        </InputLabel>
                        <Select
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="text"
                          {...register(`terminalId`, {
                            required: true,
                            maxLength: 7,
                          })}
                        >
                          {routes?.routes?.map((item) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item?.destinationStation?.stationName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  {errors.terminalId && (
                    <p className="text-red-500">
                    {translate("selectRoute")}!
                       </p>
                  )}
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                {translate("seatNo")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <div class="w-full inline-flex">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        label={translate("enterSeatNo")}
                        {...register(`seatNumber`, {
                          required: true,
                          maxLength: 20,
                        })}
                      />
                    </div>
                    {errors.seatNumber && (
                      <p className="text-red-500">{translate("enterSeatNo")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                {translate("scheduleTime")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <div class="w-full inline-flex">
                      <Controller
                        name="reservationDate"
                        control={control}
                        render={({ field }) => (
                          <Datepicker
                            classNames="w-full focus:outline-none focus:text-gray-600 b p-2"
                            placeholderText="1:30 PM"
                            {...field}
                            Selected={field.value}
                            onChange={(reservationDate) =>
                              field.onChange(reservationDate)
                            }
                            asSingle={true}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeIntervals={15}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div class="w-full p-4 text-right text-gray-500">
                {/* {userInfo ? (
                  <button className="w-full md:w-2/3 focus:outline-none mr-4 bg-blue-400 hover:bg-indigo-500 text-white font-bold xl:text-base md:text-base border border-gray-300 w-full text-sm py-2.5 outline-blue-500 rounded">
                    Save
                  </button>
                ) : ( */}
                <button
                  className="md:w-2/3 focus:outline-none mr-4 bg-blue-400 hover:bg-indigo-500 text-white font-bold xl:text-base md:text-base border border-gray-300 w-full text-sm py-2.5 outline-blue-500 rounded"
                  // onClick={() => setShowLogin(true)}
                >
                  {translate("button")}
                </button>
                {/* )} */}
              </div>
            </Paper>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default NewTicketing;
