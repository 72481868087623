import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect,useState } from "react";
import { FiPlus } from "react-icons/fi";
//import { Link } from "react-router-dom";
import Table from "../../components/Table";
import { viewDriver, deleteDriver,singleDriver } from "../../states/actions/driverAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Avatar } from "@mui/material";
import Layout from "../../pages/layout/Layout";
import Loading from "../loading/Loading";
import { useTranslation } from "react-i18next";
import DetailModal from '../../components/modal/DetailModal';

const AllDriver = () => {
  // dispatch
  const dispatch = useDispatch();
  // hooks
  const driver = useSelector((state) => state.driver);
   console.log("all driver list :", driver);
  // use effect
  useEffect(() => {
    dispatch(viewDriver());
  }, []);

  const navigate = useNavigate();

  const handleUpdateDriver = (data) => {
    navigate("/driver/" + data.id);
  };

  function handleRemoveDriver(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deleteDriver(data.id));
    }
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }
  const { t: translate } = useTranslation(["driver/viewDriver"]);
  //  Popup data
  const [isOpen, setIsOpen] = useState(false);
  const header= ["name","licence","contactNo","email"] 
  const [newValue, setNewValue] = useState([]);
  const openModal = async(data) => {
    const result = await dispatch(singleDriver(data.id));
    const value= [result.payload?.name,result.payload?.licenseNumber,result.payload?.contactNumber,result.payload?.email] 
    const newArray = [...newValue]; 
          newArray[0] = value[0]; 
          newArray[1] = value[1]; 
          newArray[2] = value[2]; 
          newArray[3] = value[3]; 
      setNewValue(newArray);
      if(newArray.length>0) {
        setIsOpen(true);  
        } 
     };
  const closeModal = () => {
     setIsOpen(false);
     setNewValue([])
     dispatch(viewDriver());
    };
   const driverColumns = [
      // {
      //   accessorKey: "photo", //access nested data with dot notation
      //   header: "Profile",
      // },
      {
        accessorKey: "name", //access nested data with dot notation
        header: translate("name"),
      },
      {
        accessorKey: "photo", //access nested data with dot notation
        header: translate("Photo"),
        size: 100,
        Cell: ({ cell }) => (
          <div>
            <Avatar
              src={`https://yeshewabirhan.abyssiniasoftware.com${cell.getValue()}`}
              sx={{ width: 30, height: 30 }}
            />
          </div>
        ),
      },
      {
        accessorKey: "licenseNumber", //access nested data with dot notation
        header: translate("license"),
      },
      {
        accessorKey: "contactNumber", //access nested data with dot notation
        header: translate("contactNo"),
      },
      {
        accessorKey: "email", //access nested data with dot notation
        header: translate("email"),
      },
    
      // {
      //   accessorKey: "licenseDocument", //access nested data with dot notation
      //   header: "License document",
      // },
    ];
    
  return (
    <Layout>              
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Link to="/newDriver" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
              {translate("add")}
            </Button>
          </Link>
         </Box>
       {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="DriverTitle" headr={header} value={newValue} />}
        {driver?.driver ? (
          <Table
            data={driver?.driver}
            fields={driverColumns}
            numberOfRows={driver?.driver?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            //  routeLink="products"
            deleteFunction={handleRemoveDriver}
            upDatefunction={handleUpdateDriver}
            viewFunction={openModal}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};

export default AllDriver;

