export const dataProfile = [
    {
      id: 1,
      name: 'Gashu Wobe',
      salary:"12000",
      Photo:"yibe.jpg",
      paymentDate:"02"
    },
    {
        id: 2,
        name: 'Mario Kart',
        salary:"52000",
        Photo:"yibe1.jpg",
        paymentDate:"12"
      },
      {
        id: 3,
        name: 'Gudo Zeraf',
        salary:"20000",
        Photo:"yibe2.jpg",
        paymentDate:"52"

      },
      {
        id: 4,
        name: 'Deluxe',
        salary:"10000",
        Photo:"yibe3.jpg",
        paymentDate:"02"
      },
    
  ];