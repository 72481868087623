import { Box, Button, Typography } from "@mui/material";
import React, { useEffect,useState } from "react";
import { FiPlus } from "react-icons/fi";
import Table from "../../components/Table";
import DetailModal from '../../components/modal/DetailModal';
import { useTranslation } from "react-i18next";

import {
  viewAccident,
  deleteAccident,
  singleAccident
} from "../../states/actions/accidentAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../../pages/layout/Layout";
import Loading from "../loading/Loading";

const ViewAccident = () => {
  // dispatch
  const dispatch = useDispatch();
  // hooks
  const accident = useSelector((state) => state.accident);
  console.log("view all accident data :", accident);
  // use effect
  useEffect(() => {
    dispatch(viewAccident());
  }, []);

  const navigate = useNavigate();
  const handleUpdateaccident = (data) => {
    navigate("/accident/" + data.id);
  };
  function handleRemoveaccident(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deleteAccident(data.id));
    }
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }
  //  Popup data
  const [isOpen, setIsOpen] = useState(false);
  const header= ["place","accidentType","description","accidentDate","bus","driver","createdAt","updatedAt"] 
  const [newValue, setNewValue] = useState([]);
  const openModal = async(data) => {
    const result = await dispatch(singleAccident(data.id));   
    const value= [result.payload?.place,result.payload?.type,result.payload?.description,result.payload?.accidentDate,
      result.payload?.Bus?.talga,result.payload?.Driver?.name,result.payload?.createdAt,result.payload?.updatedAt] 
    const newArray = [...newValue]; 
          newArray[0] = value[0]; 
          newArray[1] = value[1]; 
          newArray[2] = value[2]; 
          newArray[3] = value[3]; 
          newArray[4] = value[4]; 
          newArray[5] = value[5]; 
          newArray[6] = value[6]; 
      setNewValue(newArray);
      if(newArray.length>0) {
        setIsOpen(true);  
        }
          };
      const closeModal = () => {
      setIsOpen(false);
      setNewValue([])
      dispatch(viewAccident());
      }
  const { t: translate } = useTranslation(["punishment/viewPunishment"]);
   const accidentColumns = [
    {
      accessorKey: "Bus.talga", //access nested data with dot notation
      header: translate("busTalga"),
    },
    {
      accessorKey: "Driver.name", //access nested data with dot notation
      header: translate("driver"),
    },
    {
      accessorKey: "place", //access nested data with dot notation
      header: translate("place"),
    },
    {
      accessorKey: "type", //access nested data with dot notation
      header: translate("type"),
    },
  ];
  
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Link to="/newaccident" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
              {translate("add")}
            </Button>
          </Link>
        </Box>
        {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="Accident Detail Information" headr={header} value={newValue} />}

        {accident?.accident ? (
          <Table
            data={accident?.accident}
            fields={accidentColumns}
            numberOfRows={accident?.accident?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            //  routeLink="products"
            deleteFunction={handleRemoveaccident}
            upDatefunction={handleUpdateaccident}
            viewFunction={openModal}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};

export default ViewAccident;

