import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { registerAssignation } from "../../states/actions/assignationAction";
import { viewBus } from "../../states/actions/busAction";
import { viewRoutes } from "../../states/actions/routeAction";
import { viewDriver } from "../../states/actions/driverAction";
import { ToastContainer } from "react-toastify";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Register from "../../components/button/registerButton";
import Layout from "../../pages/layout/Layout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";
const AssignBus = () => {
  // use form
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm();
  //  useSelector
  const buses = useSelector((state) => state.buses);
  const routes = useSelector((state) => state.routes);
  const driver = useSelector((state) => state.driver);
  // use effect
  useEffect(() => {
    dispatch(viewBus());
    dispatch(viewDriver());
    dispatch(viewRoutes());
  }, []);
  useEffect(() => {}, []);
  const dispatch = useDispatch();
  const navigate =useNavigate()
  const onSubmit = (data) => {
    const formData = new FormData();
    Object?.entries(data).map((entry) => {
      const [key, value] = entry;
      formData.append(key, value);
    });
    dispatch(registerAssignation(data));
    reset();
    setTimeout(function () {
      navigate("/allAssign");
    }, 5000);
  };
  const { t: translate } = useTranslation(["assignation/addAssignation"]);

  return (
    <Layout>
      <Box className="py-24 h-screen">
        <div className="mx-auto container max-w-2xl md:w-3/4 shadow-md">
          <div className="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
            <div className="max-w-sm mx-auto md:w-full md:mx-0">
              <div className="inline-flex items-center space-x-4">
           <Typography variant="h6">{translate("header")}</Typography>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Paper className=" space-y-2 shadow pl-3">
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                 {translate("bus")}
                 </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                        {translate("selectBus")}
                        </InputLabel>
                      <Select
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`busId`, {
                          required: true,
                        })}
                      >
                        {buses?.buses?.map((bus) => (
                          <MenuItem key={bus.id} value={bus.id}>
                            {bus.talga}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    </Box>
                    {errors.busId && (
                      <p className="text-red-500">{translate("selectTalga")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("driver")}
                  </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                  <Box class="w-full inline-flex">
                  <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                        {translate("selectDriver")}
                        </InputLabel>
                      <Select
                      variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`driverId`, {
                          required:true
                        })}
                      >
                        {driver?.driver?.map((driv) => (
                          <MenuItem key={driv.id} value={driv.id}>
                            {driv.name}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    </Box>
                    {errors.driverId && (
                      <p className="text-red-500">{translate("selectDriver")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                {translate("router")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                        {translate("selectRouter")}
                        </InputLabel>
                      <Select
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`routeId`, {
                          required:true
                        })}
                      >
                        {routes?.routes?.map((route) => (
                          <MenuItem key={route.id} value={route.id}>
                            {route.sourceStation?.stationName}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    </Box>
                    {errors.routeId && (
                      <p className="text-red-500">{translate("selectRouter")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                {translate("type")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                    <TextField
                        label={translate("enterType")}
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`assignationType`, {
                          required: true,
                          maxLength: 200,
                        })}
                      />
                    </Box>
                    {errors.assignationType && (
                      <p className="text-red-500">{translate("enterType")}!</p>
                    )}
                  </div>
                </div>
              </div>
              {/* <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                {translate("distance")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                    <TextField
                        label={translate("enterDistance")}
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`distance`, {
                          maxLength: 200,
                        })}
                      />
                    </Box>
                    {errors.assignationType && (
                      <p className="text-red-500">{translate("enterDistance")}!</p>
                    )}
                  </div>
                </div>
              </div> */}
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                {translate("reason")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                    <TextField
                        label={translate("enterReason")}
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`reason`, {
                          maxLength: 200,
                        })}
                      />
                    </Box>
                    {errors.assignationType && (
                      <p className="text-red-500">{translate("enterReason")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">{translate("startTime")}</Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                  <Box class="w-full inline-flex text-base bg-slate-500 rounded-md">
                      <Controller
                        name="startTime"
                        control={control}
                        render={({ field }) => (
                          <Datepicker
                            placeholderText="eg. January 8, 2024 7:15 PM"
                            {...field}
                            selected={field.value}
                            onChange={(startTime) => field.onChange(startTime)}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeIntervals={15}
                            className=" w-full text-base text-white bg-slate-500 p-2"
                          />
                        )}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">{translate("endTime")}</Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex bg-slate-500 rounded-md">
                      <Controller
                        name="endTime"
                        control={control}
                        render={({ field }) => (
                          <div className="flex-grow">
                          <Datepicker
                            placeholderText="eg.January 8, 2024 8:15 PM"
                            {...field}
                            selected={field.value}
                            onChange={(endTime) => field.onChange(endTime)}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeIntervals={15}
                            className=" w-full text-white bg-slate-500 p-2"
                          />
                          </div>
                        )}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <Divider />
              <div class="w-full pb-2 pt-3 text-right text-gray-500">
                <Register btnName={translate("save")} />
              </div>
            </Paper>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default AssignBus;
