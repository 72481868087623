import { Box, Button, Typography } from "@mui/material";
import React, { useEffect,useState } from "react";
import { FiPlus } from "react-icons/fi";
import Table from "../../components/Table";
import { viewRoutes, deleteRoute ,singleRoute} from "../../states/actions/routeAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../../pages/layout/Layout";
import Loading from "../loading/Loading";
import { useTranslation } from "react-i18next";
import DetailModal from '../../components/modal/DetailModal';

const ViewRoutes = () => {
  // dispatch
  const dispatch = useDispatch();
  // hooks
  const routes = useSelector((state) => state.routes);
  // use effect
  useEffect(() => {
    dispatch(viewRoutes());
  }, []);
  const navigate = useNavigate();
  const handleUpdateBusOwner = (data) => {
    navigate("/updateRoute/" + data.id);
  };

  function handleRemoveBusOwner(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deleteRoute(data.id));
    }
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  }
            //  Popup data
            const [isOpen, setIsOpen] = useState(false);
            const header= ["sourceStation","destinationStation","asphaltDistance","graveDistance","estimatedTime","createdAt","updatedAt"] 
            const [newValue, setNewValue] = useState([]);
            const openModal = async(data) => {
              const result = await dispatch(singleRoute(data.id));     
              const value= [result.payload?.sourceStation?.stationName,result.payload?.destinationStation?.stationName,
                result.payload?.asphaltDistance,result.payload?.graveDistance,result.payload?.estimatedTime,result.payload?.createdAt,result.payload?.updatedAt] 
              const newArray = [...newValue]; 
                    newArray[0] = value[0]; 
                    newArray[1] = value[1]; 
                    newArray[2] = value[2]; 
                    newArray[3] = value[3]; 
                    newArray[4] = value[4]; 
                    newArray[5] = value[5]; 
                    newArray[6] = value[6]; 
                setNewValue(newArray);
                if(newArray.length>0) {
                  setIsOpen(true);  
                  }
               };
               const closeModal = () => {
                setIsOpen(false);
                setNewValue([])
                dispatch(viewRoutes());
               }
        const { t: translate } = useTranslation(["router/viewRouter"]);
         const routeColumns = [
                {
                  accessorKey: "destinationStation.stationName",
                  header: translate("station"),
                },
                //sourceStation
                {
                  accessorKey: "sourceStation.stationName", //access nested data with dot notation
                  header: translate("destination"),
                },
                {
                  accessorKey: "asphaltDistance", //access nested data with dot notation
                  header: translate("asphaltDistance"),
                },
                {
                  accessorKey: "graveDistance", //access nested data with dot notation
                  header: translate("graveDistance"),
                },
              ];
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Link to="/newroute" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
             {translate("add")}
            </Button>
          </Link>
        </Box>
        {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="Routers Detail Information" headr={header} value={newValue} />}
        <Link to="/tarif" style={{ textDecoration: "none" }}>
            <Button
              sx={{ borderRadius: "5px" }}
            >
            View Route Tarrif Report
            </Button>
          </Link>
        {routes?.routes ? (
          <Table
            data={routes?.routes}
            fields={routeColumns}
            numberOfRows={routes?.routes?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            //  routeLink="products"
            deleteFunction={handleRemoveBusOwner}
            upDatefunction={handleUpdateBusOwner}
            viewFunction={openModal}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default ViewRoutes;
