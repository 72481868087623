import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { viewDriver } from "../../states/actions/driverAction";
import { registerAccident } from "../../states/actions/accidentAction";
import { useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { viewBus } from "../../states/actions/busAction";
import moment from "moment/moment";
import Layout from "../../pages/layout/Layout";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const NewAccident = () => {
  // use form
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (accidentData) => {
    // pass the data form data
    const formData = new FormData();
    Object.entries(accidentData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    let newDate = moment(accidentData).format("yyyy-MM-DD");
    const newData = {
      ...accidentData,
      accidentDate: newDate,
    };
    // dispatch
    dispatch(registerAccident(newData));
    reset();
    setTimeout(function () {
      navigate("/allAccident");
    }, 5000);
  };
  const buses = useSelector((state) => state.buses);
  const driver = useSelector((state) => state.driver);
  // use effect
  useEffect(() => {
    dispatch(viewBus());
    dispatch(viewDriver());
  }, []);
  const { t: translate } = useTranslation(["accident"]);
  return (
    <Layout>
      <Box class="py-24 h-screen">
        <div class="mx-auto container max-w-2xl md:w-3/4 shadow-md">
          <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
            <div class="max-w-sm mx-auto md:w-full md:mx-0">
              <div class="inline-flex items-center space-x-4">
                <Typography variant="h6">
                  {translate("accidentData")}
                </Typography>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className=" shadow">
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  {translate("busTalga")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("selectTalga")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={buses?.id}
                          {...register(`busId`, {
                            required: true,
                            maxLength: 7,
                          })}
                        >
                          {buses?.buses?.map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.talga}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  {errors.busId && (
                    <p className="text-red-500">{translate("selectBus")}!</p>
                  )}
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  {translate("driverPhone")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("selectPhone")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={driver?.id}
                          {...register(`driverId`, {
                            required: true,
                            maxLength: 70,
                          })}
                        >
                          {driver?.driver?.map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.contactNumber}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  {errors.driverId && (
                    <p className="text-red-500">{translate("selectDriver")}!</p>
                  )}
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-gray-500 items-center">
                <Typography class="md:w-4/12 mx-auto max-w-sm">
                  {translate("place")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <TextField
                        label={translate("bahirdar")}
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`place`, {
                          required: true,
                          maxLength: 20,
                        })}
                      />
                    </Box>
                    {errors.place && (
                      <p className="text-red-500">{translate("enterPlace")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  {translate("accidentType")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <FormControl fullWidth size="small">
                        {/* <InputLabel id="demo-simple-select-label">
                          Select Type
                        </InputLabel> */}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value="easy"
                          {...register(`type`, {
                            required: true,
                            maxLength: 700,
                          })}
                        >
                          <MenuItem value="easy">{translate("acc1")}</MenuItem>
                          <MenuItem value="easy">{translate("acc2")}</MenuItem>
                          <MenuItem value="easy">{translate("acc3")}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  {errors.type && (
                    <p className="text-red-500">{translate("enterType")}!</p>
                  )}
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-8 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  {translate("AccidentDate")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box className="w-full inline-flex border">
                      <Controller
                        name="accidentDate"
                        control={control}
                        render={({ field }) => (
                          <Datepicker
                            {...field}
                            selected={field.value}
                            onChange={(accidentDate) =>
                              field.onChange(accidentDate)
                            }
                            asSingle={true}
                            showTimeSelect
                          />
                        )}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full space-y-1 md:space-y-0 p-4 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  {translate("Description")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <TextField
                        label={translate("accidentdescription")}
                        variant="outlined"
                        rows={2}
                        fullWidth
                        multiline
                        type="text"
                        {...register(`description`, {
                          required: true,
                          maxLength: 700,
                        })}
                      />
                    </Box>
                    {errors.description && (
                      <p className="text-red-500">
                        ={translate("enterDescription")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div class="w-full pb-2 text-right text-gray-500 item-center">
                <button className="w-full md:w-2/3 focus:outline-none mr-4 bg-blue-400 hover:bg-indigo-500 text-white font-bold xl:text-base md:text-base border border-gray-300 w-full text-sm py-2.5 outline-blue-500 rounded">
                  {translate("button1")}
                </button>
              </div>
            </Paper>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default NewAccident;
