import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import { viewItem, deleteItem,singleItem} from "../../../states/actions/lostItemAction";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../../../pages/layout/Layout";
import Loading from "../../loading/Loading";
import DetailModal from '../../../components/modal/DetailModal';

const ViewLostitem = () => {
  // dispatch
  const dispatch = useDispatch();
  // hooks
  const itemList = useSelector((state) => state.itemList);
  console.log("all item : ", itemList);
  // use effect
  useEffect(() => {
    dispatch(viewItem());
  }, []);

  const navigate = useNavigate();

  const handleUpdateLostItem = (data) => {
    navigate("/lostItem/" + data.materialId);
  };
  function handleRemoveLostItem(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deleteItem(data.materialId));
    }
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }
          //  Popup data
          const [isOpen, setIsOpen] = useState(false);
          const header= ["itemName","description","contactPerName","email","contactNo","createdAt","updatedAt"] 
          const [newValue, setNewValue] = useState([]);
          const openModal = async(data) => {
            const result = await dispatch(singleItem(data.materialId));                    
            const value= [result.payload?.itemName,result.payload?.description,result.payload?.contactPerson,result.payload?.contactEmail,result.payload?.contactPhone,result.payload?.createdAt,result.payload?.updatedAt] 
            const newArray = [...newValue]; 
                  newArray[0] = value[0]; 
                  newArray[1] = value[1]; 
                  newArray[2] = value[2]; 
                  newArray[3] = value[3]; 
                  newArray[4] = value[4]; 
                  newArray[5] = value[5]; 
              setNewValue(newArray);
              if(newArray.length>0) {
                setIsOpen(true);  
                }
             };
             const closeModal = () => {
              setIsOpen(false);
              setNewValue([])
              dispatch(viewItem());
             }
   const { t: translate } = useTranslation(["lostItem/viewLostItem"]);   
   const itemColumns = [
    {
      accessorKey: "itemName", //access nested data with dot notation
      header: translate("lostItem"),
    },
    {
      accessorKey: "contactPerson", //access nested data with dot notation
      header: translate("contact"),
    },
    {
      accessorKey: "contactEmail", //access nested data with dot notation
      header: translate("email"),
    },
    {
      accessorKey: "contactPhone", //access nested data with dot notation
      header: translate("phone"),
    },
    {
      accessorKey: "description", //access nested data with dot notation
      header: translate("description"),
    },
  ];
  
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Link to="/newLostItem" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
           {translate("add")}
            </Button>
          </Link>
        </Box>
        {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="lostItemTitle" headr={header} value={newValue} />}

        {itemList?.itemList ? (
          <Table
            data={itemList?.itemList}
            fields={itemColumns}
            numberOfRows={itemList?.itemList?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            //  routeLink="products"
            deleteFunction={handleRemoveLostItem}
            upDatefunction={handleUpdateLostItem}
            viewFunction={openModal}

          />
        ) : (
          <Loading />
        )}
      </Box>
    </Layout>
  );
};
export default ViewLostitem;

