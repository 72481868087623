import { BsCurrencyDollar } from "react-icons/bs";
import { FiShoppingBag, FiUsers, FiHome, FiSettings } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";

import {
  FaBusAlt,
  FaUsers,
  FaRegBuilding,
  FaCarCrash,
  FaOpencart,
  FaRoute,
  FaTicketAlt,
  FaSearchLocation,
  FaWalking,
  FaMarker,
  FaEdit,
  FaCcAmazonPay,
  FaArtstation,
  FaMandalorian,
  FaWrench,
  FaWheelchair,
  FaPenSquare,
} from "react-icons/fa";
// var userInfoString = localStorage.getItem("userInfo");
// var userInfo=JSON.parse(userInfoString)
// var userRole=userInfo.role
export const adminLinks = [
  {
    name: "home",
    icon: <FiHome />,
    url: "/",
    role:"all"
  },

  {
    name: "busOwnerName",
    icon: <FiUsers />,
    subLinks: [
      {
        name: "addBusOwner",
        url: "/addBusOwner",
      },
      {
        name: "allBusOwners",
        url: "/allBusOwners",
      },
    ],
  },
  {
    name: "maintenance",
    icon: <FaWrench />,
    subLinks: [
      {
        name: "addMaintenance",
        url: "/addMaintenance",
      },
      {
        name: "allMaintenance",
        url: "/maintenanceList",
      },
    ],
  },
  {
    name: "association",
    icon: <FaRegBuilding />,
    subLinks: [
      {
        name: "addAssociation",
        url: "/addAssociation",
      },
      {
        name: "viewAssociation",
        url: "/AllAssociation",
      },
    ],
  },
  {
    name: "bus",
    icon: <FaBusAlt />,
    url: "/AllBuses",
  },
  {
    name: "driver",
    icon: <FaMandalorian />,
    subLinks: [
      {
        name: "addDriver",
        url: "/newDriver",
      },
      {
        name: "driverList",
        url: "/allDriver",
      },
    ],
  },

  {
    name: "employee",
    icon: <FaUsers />,
    subLinks: [
      {
        name: "newEmployee",
        url: "/newEmployee",
      },
      {
        name: "employeeList",
        url: "/allEmployee",
      },
    ],
  },
  {
    name: "lostItem",
    icon: <FaOpencart />,
    url: "/AllLostItem",
  },
  {
    name: "route",
    icon: <FaRoute />,
    subLinks: [
      {
        name: "addRoute",
        url: "/newroute",
      },
      {
        name: "viewRoute",
        url: "/allRoutes",
      },
    ],
  },
  {
    name: "station",
    icon: <FaArtstation />,
    subLinks: [
      {
        name: "newStation",
        url: "/NewStation",
      },
      {
        name: "stationList",
        url: "/ViewStation",
      },
    ],
  },
  {
    name: "passenger",
    icon: <FaWalking />,
    subLinks: [
      {
        name: "newPassenger",
        url: "/addPAssenger",
      },
      {
        name: "passengerList",
        url: "/allPassenger",
      },
    ],
  },
  {
    name: "assignation",
    icon: <FaPenSquare />,
    subLinks: [
      {
        name: "newAssignation",
        url: "/newAssign",
      },
      {
        name: "assignationList",
        url: "/allAssign",
      },
    ],
  },

  {
    name: "feedback",
    icon: <FaMarker />,
    subLinks: [
      {
        name: "newFeedback",
        url: "/addFeedBack",
      },
      {
        name: "feedbackList",
        url: "/allFeedback",
      },
    ],
  },
  {
    name: "payment",
    icon: <FaCcAmazonPay />,
    subLinks: [
      {
        name: "allPayment",
        url: "/viewPAyment",
      },
      {
        name: "newPayment",
        url: "/newPayment",
      },
    ],
  },
  {
    name: "ticketing",
    icon: <FaTicketAlt />,
    url: "/allTicketing",
  },

  {
    name: "AccidentPunish",
    icon: <FaCarCrash />,
    subLinks: [
      {
        name: "newAccident",
        url: "/newAccident",
      },
      {
        name: "allAccident",
        url: "/allAccident",
      },
      {
        name: "addPunishment",
        url: "/newPunishment",
      },
      {
        name: "viewPunishment",
        url: "/allPunishment",
      },
    ],
  },
  {
    name: "tracking",
    icon: <FaSearchLocation />,
    url: "/viewTracking",
  },
  {
    name: "setting",
    icon: <FiSettings />,
    url: "/setting",
  },
];
export const employeeLinks = [
  {
    name: "home",
    icon: <FiHome />,
    url: "/",
    role:"all"
  },
  {
    name: "employee",
    icon: <FaUsers />,
    subLinks: [
      {
        name: "newEmployee",
        url: "/newEmployee",
      },
      {
        name: "employeeList",
        url: "/allEmployee",
      },
    ],
  },
];
export const ticketingAgentLinks = [
  {
    name: "home",
    icon: <FiHome />,
    url: "/",
    role:"all"
  },
  {
    name: "ticketing",
    icon: <FaTicketAlt />,
    url: "/allTicketing",
  },
  {
    name: "bus",
    icon: <FaBusAlt />,
    url: "/AllBuses",
  },
];

export const driverLinks = [
  {
    name: "home",
    icon: <FiHome />,
    url: "/",
    role:"all"
  },
  {
    name: "driver",
    icon: <FaMandalorian />,
    subLinks: [
      {
        name: "addDriver",
        url: "/newDriver",
      },
      {
        name: "driverList",
        url: "/allDriver",
      },
    ],
  },
  {
    name: "bus",
    icon: <FaBusAlt />,
    url: "/AllBuses",
  },
];