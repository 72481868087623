import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  singlePayment,
  updatePayment,
} from "../../states/actions/paymentAction";
import { viewAssociation } from "../../states/actions/associationAction";
import { viewDriver } from "../../states/actions/driverAction";
import { useParams } from "react-router-dom";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import Register from "../../components/button/registerButton";
import Layout from "../../pages/layout/Layout";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  Select,
  MenuItem,
  FormControl,InputLabel
} from "@mui/material";
import { viewStation } from "../../states/actions/stationAction";
const UpdatePayment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  // use form
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm();
  // use effect
  useEffect(() => {
    onReset();
  }, []);
  const onReset = async () => {
    const result = await dispatch(singlePayment(id));
    reset(result.payload);
  };
  const association = useSelector((state) => state.association);
  const stations = useSelector((state) => state.stations);
  const driver = useSelector((state) => state.driver);
  // use effect
  useEffect(() => {
    dispatch(viewAssociation());
    dispatch(viewDriver());
    dispatch(viewStation())
  }, []);

  const onSubmit = (data) => {
    const formData = new FormData();
    Object?.entries(data).map((entry) => {
      const [key, value] = entry;
      formData.append(key, value);
    });

    dispatch(updatePayment(data));
  };
  const { t: translate } = useTranslation(["payment/addPayment"]);
  return (
    <Layout>
      <Box className="py-24 h-screen">
        <div class="mx-auto container max-w-2xl md:w-3/4 shadow-md">
          <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
            <div class="max-w-sm mx-auto md:w-full md:mx-0">
              <div class="inline-flex items-center space-x-4">
              <Typography variant="h6">{translate("updateHeader")}</Typography>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Paper className=" space-y-2 shadow">
          <Divider />
          <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">{translate("association")}</Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("selectAssociation")}
                        </InputLabel>
                      <Select
                      variant="outlined"
                        size="small"
                        fullWidth
                        type="text" 
                     {...register(`associationId`,{
                        })}
                      >
                        {association?.association?.map((association) => (
                          <MenuItem
                            key={association.associationId}
                            value={association.associationId}
                          >
                          {association.associationName}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
              </div>
             <Divider />
             <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">{translate("driver")}</Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("selectDriver")}
                        </InputLabel>
                      <Select
                      variant="outlined"
                        size="small"
                        fullWidth
                        type="text" 
                        {...register(`driverId`,{
                        })}
                      >
                        {driver?.driver?.map((driv) => (
                          <MenuItem key={driv.id} value={driv.id}>
                            {driv.name}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
              </div>
             <Divider />
             <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">{translate("station")}</Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("selectStation")}
                        </InputLabel>
                      <Select
                      variant="outlined"
                        size="small"
                        fullWidth
                        type="text" 
                        {...register(`stationId`,{
                        })}
                      >
                        {stations?.stations?.map((station) => (
                          <MenuItem key={station.stationId} value={station.stationId}>
                            {station.stationName}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
              </div>
             <Divider />
             <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">{translate("amount")}</Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                      <TextField
                      variant="outlined"
                        size="small"
                        fullWidth
                        type="text" 
                        {...register(`amount`, {  
                          // pattern: /^[a-zA-Z]+$/,
                        })}
                      />
                    </Box>
                  </div>
                </div>
              </div>
             <Divider />
             <div class="md:inline-flex w-full md:space-y-0 p-4 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">{translate("date")}</Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <Box class="w-full inline-flex bg-slate-500 rounded-md">                    
                      <Controller
                        name="paymentDate"
                        control={control}
                        render={({ field }) => (
                          <Datepicker
                            {...field}
                            onChange={(paymentDate) => field.onChange(paymentDate)}
                            selected={field.value ? new Date(field.value) : null}
                            dateFormat="MMMM d, yyyy"
                            timeIntervals={15}
                            className=" w-full text-white bg-slate-500 p-2"
                          />
                        )}
                      />
                    </Box>
                </div>
              </div>
              <div class="w-full pb-2 pt-4 text-right text-gray-500">
                <Register btnName={translate("button")} />
              </div>
            </Paper>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default UpdatePayment;
