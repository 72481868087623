// import React, { useState } from "react";
// import ReactApexChart from "react-apexcharts";
// import { lineChartData, lineChartOptions } from "../../../data/chartData";

// const LineChart = () => {
//   const [chartData, setChartData] = useState([]);
//   const [chartOptions, setChartOptions] = useState([]);

//   return (
//     <ReactApexChart
//       options={lineChartOptions}
//       series={lineChartData}
//       type="line"
//       width="100%"
//       height="100%"
//     />
//   );
// };
//  export default LineChart;

// import React from "react";
// import ReactApexChart from "react-apexcharts";

// class LineChart extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       chartData: [],
//       chartOptions: {},
//     };
//   }

//   componentDidMount() {
//     this.setState({
//       chartData: this.props.chartData,
//       chartOptions: this.props.chartOptions,
//     });
//   }

//   render() {
//     return (
//       <ReactApexChart
//         options={this.state.chartOptions}
//         series={this.state.chartData}
//         type="line"
//         width="100%"
//         height="100%"
//       />
//     );
//   }
// }

// export default LineChart;

import React, { useEffect, useState } from "react";
import { Line,Bar,Pie } from "react-chartjs-2";
import { Avatar, Box, Divider, Grid, Paper, Typography } from "@mui/material";
import {CategoryScale} from 'chart.js'; 
import { registerables, Chart } from "chart.js";
import {dataProfile} from "./data"
import {SalaryData} from "./salaryData"
Chart.register(...registerables);
const LineChart = () => {
  const [data, setData] =useState(dataProfile || '');
  const [salaryData, setDataAllow] =useState(SalaryData || '');
  // console.log(salaryData.map((item)=>item.month))
  useEffect(()=>{
    setData(dataProfile);
     },[dataProfile]);
     useEffect(()=>{
      setDataAllow(salaryData);
       },[salaryData]);
    const pieChartData = {
      labels: salaryData.map((item)=>item.month),
        datasets: [{
          data: data.map((item)=>item.salary),
            label: "Infected People",
            backgroundColor: ["#00ff00","#ff0000","#ffff00","#00ffff","	#0040ff" ],
            hoverBackgroundColor: ["#175000", "#ff00ff","	#8000ff","#bfff00","#00ff40" ]
        }]
      };
    const pieChart = (
        <Pie
          type="pie"
          width={80}
          height={40}
          options={{
            title: {
              display: true,
              text: "COVID-19 Cases of Last 3 Months",
              fontSize: 15
            },
            legend: {
              display: true, //Is the legend shown?
              position: "top" //Position of the legend.
            }
          }}
          data={pieChartData}
        />
      );
    return (
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          padding: "15px",
          height: { xs: "380px", md: "100%" },
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
        }}
      >
        <Typography variant="h5">Ticketing</Typography>
        {pieChart}
      </Paper>
    );
  };
export default LineChart;


