import { Box, Typography, Button } from "@mui/material";
import React, { useEffect,useState } from "react";
import Table from "../../components/Table";
import Loading from "../loading/Loading";
import { FiPlus } from "react-icons/fi";
import {
  viewAssignation,
  deleteassignation,
  singleAssignation
} from "../../states/actions/assignationAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../../pages/layout/Layout";
import DetailModal from '../../components/modal/DetailModal';
import { useTranslation } from "react-i18next";

const AllAssigned = () => {
  // dispatch
  const dispatch = useDispatch();
  // hooks
  const assignation = useSelector((state) => state.assignation);
  // use effect
  useEffect(() => {
    dispatch(viewAssignation());
  }, []);

  const navigate = useNavigate();

  const handleUpdateAssigned = (data) => {
    navigate("/assign/" + data.id);
  };
  function handleRemoveAssigned(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deleteassignation(data.id));
    }
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }
     //  Popup data
        const [isOpen, setIsOpen] = useState(false);
        const header= ["bus","driver","router","assignType","startTime","endTime","createdAt","updatedAt"] 
        const [newValue, setNewValue] = useState([]);
        const openModal = async(data) => {
          const result = await dispatch(singleAssignation(data.id));
          const value= [result.payload?.bus?.talga,result.payload?.driver?.name,result.payload?.route?.distance,result.payload?.assignationType,result.payload?.startTime
                       ,result.payload?.endTime,result.payload?.createdAt,result.payload?.updatedAt] 
          const newArray = [...newValue]; 
                newArray[0] = value[0]; 
                newArray[1] = value[1]; 
                newArray[2] = value[2]; 
                newArray[3] = value[3]; 
                newArray[4] = value[4]; 
                newArray[5] = value[5]; 
                newArray[6] = value[6]; 
                newArray[7] = value[7]; 
            setNewValue(newArray);
            if(newArray.length>0) {
              setIsOpen(true);  
              }
           };
           const closeModal = () => {
            setIsOpen(false);
            setNewValue([])
            dispatch(viewAssignation());
           }
           const { t: translate } = useTranslation(["assignation/viewAssignation"]);
           const assignationColumns = [
            {
              accessorKey: "bus.talga", //access nested data with dot notation
              header: translate("bus"),
            },
            {
              accessorKey: "driver.name", 
              header: translate("driver"),
            },
            {
              accessorKey: "route.sourceStation.stationName", 
              header: translate("router"),
            },
            {
              accessorKey: "assignationType",
              header: translate("type"),
            },
            {
              accessorKey: "startTime",
              header: translate("startTime"),
            },
            {
              accessorKey: "endTime",
              header: translate("endTime"),
            },
          ];
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Assignation List</Typography>
          <Link to="/newAssign" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
              Add Assignation
            </Button>
          </Link>
        </Box>
        {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="assignationTitle" headr={header} value={newValue} />}

        {assignation?.assignation ? (
          <Table
            data={assignation?.assignation}
            fields={assignationColumns}
            numberOfRows={assignation?.assignation?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            //  routeLink="products"
            deleteFunction={handleRemoveAssigned}
            upDatefunction={handleUpdateAssigned}
            viewFunction={openModal}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};

export default AllAssigned;


