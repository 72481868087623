import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { addItem } from "../../../states/actions/lostItemAction";
import Layout from "../../../pages/layout/Layout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
} from "@mui/material";
const NewLostitem = () => {
  const buttonName = "Register";
  // use form
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (maintenanceData) => {
    // pass the data form data
    const formData = new FormData();
    Object.entries(maintenanceData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // console.log("send data info :", maintenanceData);
    // dispatch
    dispatch(addItem(maintenanceData));
    reset();
    setTimeout(function () {
      navigate("/AllLostItem");
    }, 5000);
  };
  const { t: translate } = useTranslation(["lostItem/addLostItem"]);
  return (
    <Layout>
      <Box className="py-24 h-screen">
        <div className="mx-auto container max-w-2xl md:w-3/4 shadow-md">
          <div className="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
            <div className="max-w-sm mx-auto md:w-full md:mx-0">
              <div className="inline-flex items-center space-x-4">
                <Typography variant="h6">{translate("header")}</Typography>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className=" space-y-2 shadow pl-3">
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-3 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("lostItem")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        label={translate("enterItem")}
                        {...register(`itemName`, {
                          required: true,
                          maxLength: 120,
                        })}
                      />
                    </Box>
                    {errors.itemName && (
                      <p className="text-red-500">{translate("enterItem")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full md:space-y-0 p-3 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("contact")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        label={translate("enterContact")}
                        {...register(`contactPerson`, {
                          required: true,
                          maxLength: 50,
                        })}
                      />
                    </Box>
                    {errors.contactPerson && (
                      <p className="text-red-500">
                        {translate("enterContact")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full md:space-y-0 p-3 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("email")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <Box class="pt-2 w-1/12 bg-gray-100">
                        <svg
                          fill="none"
                          class="w-6 text-gray-400 mx-auto"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                      </Box>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        label="men@gmail.com"
                        {...register("contactEmail", {
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                      />
                    </Box>
                    {errors.contactEmail && (
                      <p className="text-red-500">
                        {translate("eneterEmail")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
{/* 
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-3 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("phone")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <Box class="pt-2 w-1/12 bg-gray-100">
                        <svg
                          fill="none"
                          class="w-6 text-gray-400 mx-auto"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                          />
                        </svg>
                      </Box>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        label="+251912341234"
                        {...register("contactPhone", {
                          required: true,
                          pattern: /((^(\+251|0)(9|7)\d{2})-?\d{6})$/,
                        })}
                      />
                    </Box>
                    {errors.contactPhone && (
                      <p className="text-red-500">{translate("phone")}!</p>
                    )}
                  </div>
                </div>
              </div> */}
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-3 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("description")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <TextField
                        rows="4"
                        variant="outlined"
                        multiline
                        fullWidth
                        type="text"
                        label={translate("writeDescription")}
                        {...register(`description`, {
                          required: true,
                          maxLength: 1000,
                        })}
                      />
                    </Box>
                    {errors.description && (
                      <p className="text-red-500">{translate("writeDescription")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="w-full p-4 text-right text-gray-500">
                <button className="md:w-2/3 focus:outline-none mr-4 bg-blue-400 hover:bg-indigo-500 text-white font-bold xl:text-base md:text-base border border-gray-300 w-full text-sm py-2.5 outline-blue-500 rounded">
                  {translate("button")}
                </button>
              </div>
            </Paper>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default NewLostitem;
