import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import Loading from "../loading/Loading";
import Table from "../../components/Table";
import DetailModal from "../../components/modal/DetailModal";

import {
  viewBusOwner,
  deleteBusOwner,
  singleBusOwner,
} from "../../states/actions/busOwnerAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../layout/Layout";
import { useTranslation } from "react-i18next";

const ViewBusOwners = () => {
  // dispatch
  const dispatch = useDispatch();
  // hooks
  const busOwner = useSelector((state) => state.busOwner);
  // use effect
  useEffect(() => {
    dispatch(viewBusOwner());
  }, []);

  const navigate = useNavigate();

  const handleUpdateBusOwner = (data) => {
    navigate("/BusOwner/" + data?.busOwnerId);
  };
  function handleRemoveBusOwner(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deleteBusOwner(data?.busOwnerId));
    }
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }
  const handleNavigate = () => {
    navigate("/addBusOwner");
  };
  const { t: translate } = useTranslation(["busOwner/viewBusOwners"]);
    //  Popup data
    const [isOpen, setIsOpen] = useState(false);
    const header= ["firstName","lastName","email","contactNo","address","createdAt","updatedAt"] 
    const [newValue, setNewValue] = useState([]);
    const openModal = async(data) => {
      const result = await dispatch(singleBusOwner(data.busOwnerId));     
      const value= [result.payload?.firstName,result.payload?.lastName,result.payload?.email,result.payload?.phone,
        result.payload?.address,result.payload?.createdAt,result.payload?.updatedAt] 
      const newArray = [...newValue]; 
            newArray[0] = value[0]; 
            newArray[1] = value[1]; 
            newArray[2] = value[2]; 
            newArray[3] = value[3]; 
            newArray[4] = value[4]; 
            newArray[5] = value[5]; 
            newArray[6] = value[6]; 
        setNewValue(newArray);
        if(newArray.length>0) {
          setIsOpen(true);  
          }        
        };
        const closeModal = () => {
        setIsOpen(false);
        setNewValue([])
        dispatch(viewBusOwner());
        }
     const busOwnerColumns = [
          {
            accessorKey: "firstName", //access nested data with dot notation
            header: translate("firstName"),
          },
          {
            accessorKey: "lastName", //access nested data with dot notation
            header: translate("lastName"),
          },
          {
            accessorKey: "phone", //access nested data with dot notation
            header: translate("phone"),
          },
          {
            accessorKey: "email", //access nested data with dot notation
            header: translate("email"),
          },
          {
            accessorKey: "address", //access nested data with dot notation
            header: translate("address"),
          },
        ];
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "5px" }}
            onClick={handleNavigate}
          >
            {translate("add")}
          </Button>
        </Box>
        {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="BOwnerTitle" headr={header} value={newValue} />}

        {busOwner?.busOwner ? (
          <Table
            data={busOwner?.busOwner}
            fields={busOwnerColumns}
            numberOfRows={busOwner?.busOwner?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            //  routeLink="products"
            deleteFunction={handleRemoveBusOwner}
            upDatefunction={handleUpdateBusOwner}
            viewFunction={openModal}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};

export default ViewBusOwners;


