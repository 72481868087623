import styled from "@emotion/styled";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { lineChartData, lineChartOptions } from "../../../data/chartData";
// import { stats } from "../../../data/stats";
// import LineChart from "../charts/LineChart";
// import { FiShoppingBag, FiUsers } from "react-icons/fi";
import { ViewStatistics } from "../../../states/actions/statisticsAction";
import { BsCurrencyDollar } from "react-icons/bs";
import busImg from "../../../assets/images/statsImg/Bus.jpg";
import ownerImg from "../../../assets/images/statsImg/busowner.svg";
import employImg from "../../../assets/images/statsImg/employee.svg";
 import stationImg from "../../../assets/images/statsImg/station1.jpg";
import routerImg from "../../../assets/images/statsImg/router.jpeg";
import associationImg from "../../../assets/images/statsImg/Association.svg";
import driverImg from "../../../assets/images/statsImg/driver1.png";
import paymentImg from "../../../assets/images/statsImg/payment.png";
import accidentImg from "../../../assets/images/statsImg/accident.png";
import assignImg from "../../../assets/images/statsImg/d4d1.png";
import BaseStats from "./baseStats";
// import {
//   FaBusAlt,
//   FaUsers,
//   FaRegBuilding,
//   FaCarCrash,
//   FaOpencart,
//   FaRoute,
//   FaTicketAlt,
//   FaSearchLocation,
//   FaWheelchair,
//   FaMarker,
//   FaPenSquare,
//   FaCcAmazonPay,
// } from "react-icons/fa";
const Stats = () => {
  const Item = styled(Paper)({
    padding: "5px 10px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  });
  const statstics = useSelector((state) => state.statstics);
  console.log("statistics", statstics?.statstics);
  const dispatch = useDispatch();
  // use effect
  useEffect(() => {
    dispatch(ViewStatistics());
  }, []);
  return (
    <div className=" gap-x-5 w-full pr-5 md:space-y-5 space-y-3 ">
      <div className="md:flex block md:w-[100%] w-full md:space-y-0 space-y-2 md:space-x-5 space-x-1">
         <BaseStats
          image={busImg}
          totalNo={statstics?.statstics?.buses}
          link="AllBuses"
          name="Buses"
        />
        <BaseStats
          image={ownerImg}
          totalNo={statstics?.statstics?.busOwners}
          link="allBusOwners"
          name="Busowners"
        />
        <BaseStats
          image={stationImg}
          totalNo={statstics?.statstics?.stations}
          link="ViewStation"
          name="Stations"
        />
        <BaseStats
          image={associationImg}
          totalNo={statstics?.statstics?.associations}
          link="AllAssociation"
          name="Associations"
        />
      </div>
      <div className="md:flex block md:w-[100%] w-full md:space-y-0 space-y-2 md:space-x-5 space-x-1">
        <BaseStats
          image={employImg} 
          totalNo={statstics?.statstics?.employees}
          link="allEmployee"
          name="Employees"
        />
        <BaseStats
          image={driverImg}
          totalNo={statstics?.statstics?.drivers}
          link="allAssign"
          name="Drivers"
        />
        <BaseStats
         image={routerImg}
          totalNo={statstics?.statstics?.terminals}
          link="allRoutes"
          name="Routers"
        />
      </div>
      <div className="md:flex block md:w-[100%] w-full md:space-y-0 space-y-2 md:space-x-5 space-x-1">
        <BaseStats
          image={paymentImg}
          totalNo={statstics?.statstics?.servicePayments}
          link="viewPayment"
          name="Service Payments"
        />
        <BaseStats
          image={assignImg}
          totalNo={statstics?.statstics?.busAssignations}
          link="allAssign"
          name="Bus Assignations"
        />
        <BaseStats
          image={accidentImg}
          totalNo={statstics?.statstics?.accidents}
          link="allAccident"
          name="Accidents"
        />
      </div>
    </div>
  );
};

export default Stats;
