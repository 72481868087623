import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { BsBell } from "react-icons/bs";
import { FiMenu, FiMoon, FiSun } from "react-icons/fi";
import { useColorTheme } from "../../contexts/ThemeContext";
import ProfileMenu from "./ProfileMenu";
import Communication from "../../pages/communication/communication";
import { useTranslation } from "react-i18next";

const Navbar = ({ sideBarWidth, handleDrawerToggle }) => {
  const colorMode = useColorTheme();
  const theme = useTheme();

  const currentTheme = theme.palette.mode;
  localStorage.setItem("darkMode", currentTheme || "light");

  const { t: translate, i18n } = useTranslation(["header"]);
  const handleLangChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  const currentLanguage = localStorage.getItem("i18nextLng") || "";

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${sideBarWidth}px)` },
        ml: { md: `${sideBarWidth}px` },
        boxShadow: "unset",
        backgroundColor: "background.paper",
        color: "text.primary",
        borderBottomWidth: 1,
        borderBottomColor: "divider",
      }}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Tooltip title="Menu" arrow>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <FiMenu />
              </IconButton>
            </Tooltip>

            {/* <Typography
              variant="h5"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              Home
            </Typography> */}
          </Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <Tooltip title="Language" arrow>
              <IconButton sx={{ fontSize: "20px", color: "text.primary" }}>
                {/* <BsBell /> */}
                <select
                  className="mx-3 px-3 border-none rounded-sm shadow-md"
                  onChange={handleLangChange}
                >
                  <option value="" selected hidden>
                    {currentLanguage === "am" ? "አማርኛ" : "English"}
                  </option>
                  <option value="am">አማርኛ</option>
                  <option value="en">English</option>
                </select>
              </IconButton>
            </Tooltip>

            <Communication />

            <Tooltip title="Toggle Theme" arrow>
              <IconButton
                onClick={colorMode.toggleColorMode}
                sx={{ fontSize: "20px", color: "text.primary" }}
              >
                {currentTheme === "light" ? <FiMoon /> : <FiSun />}
              </IconButton>
            </Tooltip>

            <ProfileMenu />
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;