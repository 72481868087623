import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { viewStation } from "../../states/actions/stationAction";
import Register from "../../components/button/registerButton";
import { newRoutes } from "../../states/actions/routeAction";
import Datepicker from "react-tailwindcss-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import Layout from "../../pages/layout/Layout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";
const NewRoute = () => {
  const buttonName = "Save";
  // use form
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stations = useSelector((state) => state.stations);
  // use effect
  useEffect(() => {
    dispatch(viewStation());
  }, []);

  const onSubmit = (routeData) => {
    // pass the data form data
    const formData = new FormData();
    Object.entries(routeData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    let newDate = moment(routeData).format("yyyy-MM-DD");
    const newData = {
      ...routeData,
      asphaltDistance:routeData.asphaltDistance,
      graveDistance:routeData.graveDistance,
      cost:"0",
      estimatedTime: newDate,
    };
    // dispatch
    dispatch(newRoutes(newData));
    reset();
    setTimeout(function () {
      navigate("/allRoutes");
    }, 5000);
  };
  const { t: translate } = useTranslation(["router/addRouter"]);
  return (
    <Layout>
      <Box className="py-24 h-screen">
        <div class="mx-auto container max-w-2xl md:w-3/4 shadow-md">
          <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
            <div class="max-w-sm mx-auto md:w-full md:mx-0">
              <div class="inline-flex items-center space-x-4">
                <Typography variant="h6">{translate("header")}</Typography>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className=" space-y-2 shadow">
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-3 text-gray-500 items-center">
                <Typography class="md:w-3/12 sans max-w-sm mx-auto">
                {translate("station")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("enterStation")}
                        </InputLabel>
                      <Select
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`sourceStationId`, {
                          required: true,
                          maxLength: 700,
                        })}
                      >
                      {stations?.stations?.map((station) => (
                          <MenuItem key={station.stationId} value={station.stationId}>
                            {station.stationName}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    </Box>
                    {errors.sourceStationId && (
                      <p className="text-red-500">{translate("enterStation")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-3 text-gray-500 items-center">
                <Typography class="md:w-3/12  sans max-w-sm mx-auto">
                {translate("destination")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                        {translate("enterDestination")}
                        </InputLabel>
                      <Select
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`destinationStationId`, {
                          required: true,
                          maxLength: 700,
                        })}
                      >
                      {stations?.stations?.map((station) => (
                          <MenuItem key={station.stationId} value={station.stationId}>
                            {station.stationName}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    </Box>
                    {errors.destinationStationId && (
                      <p className="text-red-500">{translate("enterDestination")}!</p>
                    )}
                  </div>
                </div>
              </div>
              {/* <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
                <Typography class="md:w-3/12  sans max-w-sm mx-auto">
                {translate("type")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                        {translate("selectType")}
                        </InputLabel>
                        <Select
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="text"
                        {...register(`assignationType`, {
                            required: true,
                            maxLength: 30,
                          })}
                        >
                          <MenuItem value={translate("asphalt")}> {translate("asphalt")}</MenuItem>
                          <MenuItem value={translate("grave")}> {translate("grave")}</MenuItem>
                          <MenuItem value={translate("both")}> {translate("both")}</MenuItem>

                        </Select>
                      </FormControl>
                    </Box>
                    </div>
                    {errors.assignationType && (
                      <p className="text-red-500">{translate("selectType")}!</p>
                    )}
                  </div>
                  </div>*/}
              <Divider /> 
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-gray-500 items-center">
                <Typography class="md:w-3/12  sans mx-auto max-w-sm">
                {translate("graveDistance")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="number"
                        label={translate("enterGrave")}
                        {...register(`graveDistance`, {
                          required: true,
                          maxLength: 40,
                        })}
                      />
                    </Box>
                    {errors.graveDistance && (
                      <p className="text-red-500">{translate("enterGrave")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-gray-500 items-center">
                <Typography class="md:w-3/12  sans mx-auto max-w-sm">
                {translate("asphaltDistance")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="number"
                        label={translate("enterAsphalt")}
                        {...register(`asphaltDistance`, {
                          required: true,
                          maxLength: 40,
                        })}
                      />
                    </Box>
                    {errors.asphaltDistance && (
                      <p className="text-red-500">{translate("enterAsphalt")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full space-y-4 md:space-y-0 p-8 text-gray-500 items-center">
                <Typography class="md:w-3/12  sans max-w-sm mx-auto">
                  {translate("time")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <div class="w-full inline-flex">
                      <Controller
                        name="estimatedTime"
                        control={control}
                        render={({ field }) => (
                          <Datepicker
                            {...field}
                            Selected={field.value}
                            onChange={(estimatedTime) =>
                              field.onChange(estimatedTime)
                            }
                            asSingle={true}
                          />
                        )}
                        class="w-full focus:outline-none focus:text-gray-600 p-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div class="w-full p-4 text-right text-gray-500">
                <button className=" md:w-2/3 focus:outline-none mr-4 bg-blue-400 hover:bg-indigo-500 text-white font-bold xl:text-base md:text-base border border-gray-300 w-full text-sm py-2.5 outline-blue-500 rounded">
                {translate("button")}
                </button>
              </div>
            </Paper>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default NewRoute;