import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { sendMessage } from "../../states/actions/communicationAction";
import { ToastContainer } from "react-toastify";
import { viewEmployee } from "../../states/actions/employeeAction";
import Layout from "../../pages/layout/Layout";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const SendMessage = () => {
  // use form
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (communicationData) => {
    // pass the data form data
    const formData = new FormData();
    Object.entries(communicationData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // dispatch
    const newData = {
      ...communicationData,
      senderId: 1,
      receiverId: 2,
    };
    dispatch(sendMessage(newData));
  };
  const employee = useSelector((state) => state.employee);
  // use effect
  useEffect(() => {
    dispatch(viewEmployee());
  }, []);
  return (
    <Layout>
      {/* <Box class="min-h-screen flex items-center">
        <div class="w-full xl:w-9/12 lg:w-10/12 md:w-11/12 mx-auto xl:mt-5 md:mt-5 mt-2">
          <Paper class="bg-white rounded mb-6 p-12">
            <div class=" gap-4 gap-y-2 text-sm  mx-4  ">
              <form
                onSubmit={handleSubmit(onSubmit)}
                headers={{ "Content-Type": "application/json" }}
              >
                <div class=" gap-20 text-sm space-y-8">
                  <p class="text-gray-500 mb-4 font-bold text-lg">
                    communication among staff members
                  </p>
                  <div>
                    <label class="text-sm text-gray-950 uppercase tracking-wide  mb-0.5 mt-2 block ml-4">
                      Receiver Name
                    </label>
                    <div class="relative flex items-center">
                      <select
                        value={employee?.id}
                        class="bg-gray-50 border border-gray-300 w-full text-sm px-4 py-2.5 rounded-md outline-blue-500"
                        {...register(`receiverId`, {
                          required: true,
                          maxLength: 7,
                        })}
                      >
                        {employee?.employee?.map((option) => (
                          <option key={option?.id} value={option?.id}>
                            {option?.firstName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.response && (
                      <p className="text-red-500">Receiver Name is required!</p>
                    )}
                  </div>
                  <div>
                    <label class="text-sm text-gray-950 uppercase tracking-wide  mb-0.5 mt-2 block ml-4">
                      Messages
                    </label>
                    <div class="relative flex items-center">
                      <textarea
                        name="Description"
                        type="text"
                        {...register(`message`, {
                          required: true,
                          maxLength: 100,
                        })}
                        class="bg-gray-50 border border-gray-300 w-full text-sm px-4 py-2.5 rounded-md outline-blue-500"
                        placeholder="I hope this letter finds you well"
                        cols="30"
                        rows="8"
                      />
                    </div>
                    {errors.response && (
                      <p className="text-red-500">Description is required!</p>
                    )}
                  </div>
                  <div>
                    <div class="relative flex justify-end">
                      <button
                        type="submit"
                        class="bg-primary w-1/2 hover:bg-hoverButton text-white font-bold py-3 px-6 rounded"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Paper>
        </div>
      </Box>
      <ToastContainer /> */}

      <Box class="py-24 h-screen">
        <div class="mx-auto container max-w-2xl md:w-3/4 shadow-md">
          <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
            <div class="max-w-sm mx-auto md:w-full md:mx-0">
              <div class="inline-flex items-center space-x-4">
                <Typography variant="h6">
                  communication among staff members
                </Typography>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className="space-y-4 shadow">
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  Receiver Name
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          select name
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={employee?.id}
                          {...register(`receiverId`, {
                            required: true,
                            maxLength: 7,
                          })}
                        >
                          {employee?.employee?.map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.firstName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  {errors.receiverId && (
                    <p className="text-red-500">Receiver Name is required!</p>
                  )}
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full space-y-1 md:space-y-0 p-4 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  Messages
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <TextField
                        label="accident description"
                        variant="outlined"
                        rows={4}
                        fullWidth
                        multiline
                        type="text"
                        {...register(`message`, {
                          required: true,
                          maxLength: 1000,
                        })}
                      />
                    </Box>
                    {errors.description && (
                      <p className="text-red-500">Description is required!</p>
                    )}
                  </div>
                </div>
              </div>
              <div class="w-full pb-2 text-right text-gray-500 item-center">
                <button className="w-full md:w-2/3 focus:outline-none mr-4 bg-blue-400 hover:bg-indigo-500 text-white font-bold xl:text-base md:text-base border border-gray-300 w-full text-sm py-2.5 outline-blue-500 rounded">
                  Send
                </button>
              </div>
            </Paper>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default SendMessage;
