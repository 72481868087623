import React from 'react';
import { Box, Button, Paper, Typography,Divider } from "@mui/material";
import Modal from '@mui/material/Modal';
import { useTranslation } from "react-i18next";
import { FiX } from "react-icons/fi";
const DetailModal = (props) => {
    const [isOpen, setIsOpen] = React.useState(props?.open);
      const data=[]
     for (let i=0;i<props?.headr.length;i++){
     data.push({head:props?.headr[i],value:props?.value[i]})
     }
    const closeModal = () => {
           props?.closeModal()
          };
   const { t: translate } = useTranslation(["popup"]);
    return (
      <>
     <Modal open={isOpen} >
     <Box
        sx={{
            display: "flex",
            alignItems:"center",
            justifyContent: "center",
            marginTop:"100px",
            border:"1px"
          }}
        >
          <Paper className=" md:w-7/12 w-full border-2 border-slate-400">
          <div className='h-16 flex'>
          <Typography variant="h6" className=" md:pl-11 pl-0 font-bold md:p-5 p-0 w-full">{translate(props?.title)}</Typography>
            <Button
              startIcon={<FiX/>}
              className=' float-end  h-1/2 top-1 rounded-lg'
              onClick={closeModal}
            >
            </Button>
          </div>
          <Divider className=' bg-slate-400'/>
            {(data?.length > 0)
          ?(
            <div className="md:flex block items-center ">
            <div className="md:w-1/2 w-full p-8 pt-6 border-r-2 border-slate-400">
            {data.slice(0, Math.ceil(data.length / 2))?.map((orgs,index) => 
           (
            <Typography key={index} variant="h7" className="text-base w-full mb-4 ">
             <p className='md:flex block mb-1'><p className='font-bold mr-1'>{translate(orgs?.head)}: </p><p> {orgs?.value}</p></p> 
             </Typography>
            ))}
            </div>
            <div className="md:w-1/2 w-full p-8 md:pt-6 pt-0 md:mt-0 -mt-6 border-r-2 border-slate-400">
            {data.slice(Math.ceil(data.length / 2))?.map((orgs,index) => 
           (
            <Typography key={index} variant="h7" className="text-base w-full mb-4">
            <p className='md:flex block mb-1'><p className='font-bold mr-1'>{translate(orgs?.head)}: </p><p> {orgs?.value}</p></p> 
             </Typography>
            ))}
            </div>
            </div>
            ):("")}           
          </Paper>
          </Box>
        </Modal>
      </>
    );
  }
  export default DetailModal
  