import { Box, Button, Typography } from "@mui/material";
import React, { useEffect} from "react";
import Table from "../../components/Table";
import { viewBus, deleteBus } from "../../states/actions/busAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import Layout from "../../pages/layout/Layout";
import { useTranslation } from "react-i18next";
import Loading from "../loading/Loading";

const AllBus = () => {
  // dispatch
  const dispatch = useDispatch();

  const buses = useSelector((state) => state.buses);
  // use effect
  useEffect(() => {
    dispatch(viewBus());
  }, []);

  const navigate = useNavigate();

  const handleUpdateBus = (data) => {
    navigate("/buses/" + data.id);
  };
  const handleSingleBus = (data) => {
    navigate("/singleBus/" + data.id);
  };
  function handleRemoveBus(data) {
    //console.log("id : ", id);
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deleteBus(data.id));
    }
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }
  const { t: translate } = useTranslation(["bus/viewBus"]);
  const busColumns = [
    {
      accessorKey: "licensePlate", //access nested data with dot notation
      header: translate("LPlate"),
    },
    {
      accessorKey: "model", //access nested data with dot notation
      header: translate("model"),
    },
    {
      accessorKey: "capacity", //access nested data with dot notation
      header: translate("capacity"),
    },
    {
      accessorKey: "sideNo", //access nested data with dot notation
      header: translate("sideNo"),
    },
    {
      accessorKey: "talga", //access nested data with dot notation
      header: translate("talga"),
    },
    {
      accessorKey: "level", //access nested data with dot notation
      header: translate("level")
    },
  ];
  
  return (
    <Layout>

      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Link to="/newBus" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
              {translate("add")}
            </Button>
          </Link>
        </Box>

        {buses?.buses ? (
          <Table
            data={buses?.buses}
            fields={busColumns}
            numberOfRows={buses?.buses?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            deleteFunction={handleRemoveBus}
            upDatefunction={handleUpdateBus}
            viewFunction={handleSingleBus}
          />
        ) : (
          <Loading />
        )}
      </Box>
    </Layout>
  );
};

export default AllBus;

