import { Box, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { FiPlus } from "react-icons/fi";
import Table from "../../components/Table";
import Loading from "../loading/Loading";
import {
  viewPassenger,
  deletePassenger,
} from "../../states/actions/passengerAction";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../../pages/layout/Layout";
const AllPassenger = () => {
  // dispatch
  const dispatch = useDispatch();
  // useSelectors
  const passenger = useSelector((state) => state.passenger);
  // use effect
  useEffect(() => {
    dispatch(viewPassenger());
  }, []);

  const navigate = useNavigate();
  //update passenger funcion
  const handleUpdatePassenger = (data) => {
    navigate("/passenger/" + data.id);
  };
  const handleViewPassenger = (data) => {
    navigate("/singlePassenger/" + data.id);
  };
  //delete passenger funcion
  function handleRemovePassenger(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deletePassenger(data.id));
    }
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }
  const { t: translate } = useTranslation(["passenger/viewPassenger"]);
   const passengerColumns = [
    {
      accessorKey: "name",
      header: translate("name"),
    },
    {
      accessorKey: "contactNumber",
      header: translate("contactNo"),
    },
    {
      accessorKey: "age",
      header: translate("age"),
    },
    {
      accessorKey: "gender",
      header: translate("gender"),
    },
  ];
  
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Link to="/addPAssenger" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
              {translate("add")}
            </Button>
          </Link>
        </Box>
        {passenger?.passenger ? (
          <Table
            data={passenger?.passenger}
            fields={passengerColumns}
            numberOfRows={passenger?.passenger?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            deleteFunction={handleRemovePassenger}
            upDatefunction={handleUpdatePassenger}
            viewFunction={handleViewPassenger}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};

export default AllPassenger;

