import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  updateStation,
  singleStation,
} from "../../states/actions/stationAction";
import { ToastContainer } from "react-toastify";
import Register from "../../components/button/registerButton";
import Layout from "../../pages/layout/Layout";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

//  Station registration component
const UpdateStation = () => {
  // use form
  const { id } = useParams();
  // dispatch
  const dispatch = useDispatch();
  // use form
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  // use effect
  useEffect(() => {
    onReset();
  }, []);
  //  reset function
  const onReset = async () => {
    const result = await dispatch(singleStation(id));
    reset(result.payload);
  };
  const stations = useSelector((state) => state.stations);
  const onSubmit = (data) => {
    const formData = new FormData();
    Object?.entries(data).map((entry) => {
      const [key, value] = entry;
      formData.append(key, value);
    });
    // dispatch
    dispatch(updateStation(data));
    if (stations?.success == true) {
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }
  };
  const { t: translate } = useTranslation(["station/addStation"]);
  return (
    <Layout>
      <Box class="md:py-20 py-16 h-screen">
        <div class="mx-auto container max-w-2xl md:w-3/4 shadow-md">
          <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
            <div class="max-w-sm mx-auto md:w-full md:mx-0">
              <div class="inline-flex items-center space-x-4">
                <Typography variant="h6">{translate("updateHeader")}</Typography>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper class=" md:space-y-2 space-y-0 ">
              <Divider />
              <div class="md:inline-flex w-full space-y-1 p-3 text-gray-700 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">{translate("name")}</Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`stationName`, {
                          maxLength: 100,
                          pattern: /[a-zA-Z]+$/,
                        })}
                      />
                    </Box>
                    {errors.stationName && (
                      <p className="text-red-500">
                      {translate("enterName")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full space-y-1 p-3 text-gray-700 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("location")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register("location", {
                          maxLength: 50,
                        })}
                      />
                    </Box>
                    {errors.location && (
                      <p className="text-red-500">{translate("enterLocation")}!</p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full space-y-1 p-3 text-gray-700 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("contactName")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register("contactPersonName", {
                          pattern: /[a-zA-Z]+$/,
                          maxLength: 70,
                        })}
                      />
                    </Box>
                    {errors.contactPersonName && (
                      <p className="text-red-500">
                      {translate("enterPrName")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full space-y-4 md:space-y-0 p-3 text-gray-700 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                {translate("contactPhone")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <div class="pt-2 w-1/12 bg-gray-100">
                        <svg
                          fill="none"
                          className="w-6 text-gray-400 mx-auto"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                          />
                        </svg>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register("contactPersonPhone", {
                          pattern: /((^(\+251|0)(9|7)\d{2})-?\d{6})$/,
                        })}
                      />
                    </Box>
                    {errors.contactPersonPhone && (
                      <p className="text-red-500">
                      {translate("enterPrPhone")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-3 text-gray-700 items-center">
                <Typography class="md:w-4/12 mx-auto max-w-sm">
                {translate("contactEmail")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex">
                      <div class="pt-2 w-1/12 bg-gray-100">
                        <svg
                          fill="none"
                          class="w-6 text-gray-400 mx-auto"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register("contactPersonEmail", {
                          pattern:
                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                      />
                    </Box>
                    {errors.contactPersonEmail && (
                      <p className="text-red-500">
                      {translate("enterPrEmail")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="w-full pb-2 pt-4 text-right text-gray-500">
              <Register btnName={translate("button")} />
              </div>
            </Paper>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default UpdateStation;
