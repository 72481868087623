import { Box, Button, Typography } from "@mui/material";
import React, { useEffect,useState } from "react";
import { FiPlus } from "react-icons/fi";
//import { Link } from "react-router-dom";
import Table from "../../../components/Table";
import { useTranslation } from "react-i18next";
import {
  viewEmployee,
  deleteEmployee,
  singleEmployee
} from "../../../states/actions/employeeAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../../../pages/layout/Layout";
import Loading from "../../loading/Loading";
import DetailModal from '../../../components/modal/DetailModal';

const AllEmployee = () => {
  // dispatch
  const dispatch = useDispatch();
  // hooks
  const employee = useSelector((state) => state.employee);
  // use effect
  useEffect(() => {
    dispatch(viewEmployee());
  }, []);

  const navigate = useNavigate();

  const handleUpdateEmployee = (data) => {
    navigate("/employee/" + data.id);
  };
  function handleRemoveEmployee(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deleteEmployee(data.id));
    }
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  }
          //  Popup data
          const [isOpen, setIsOpen] = useState(false);
          const header= ["firstName","lastName","personalEmail","role","createdAt","updatedAt"] 
          const [newValue, setNewValue] = useState([]);
          const openModal = async(data) => {
            const result = await dispatch(singleEmployee(data.id));
            const value= [result.payload?.firstName,result.payload?.lastName,result.payload?.email,result.payload?.role,result.payload?.createdAt,result.payload?.updatedAt] 
            const newArray = [...newValue]; 
                  newArray[0] = value[0]; 
                  newArray[1] = value[1]; 
                  newArray[2] = value[2]; 
                  newArray[3] = value[3]; 
                  newArray[4] = value[4]; 
                  newArray[5] = value[5]; 
              setNewValue(newArray);
              if(newArray.length>0) {
                setIsOpen(true);  
                }
             };
             const closeModal = () => {
              setIsOpen(false);
              setNewValue([])
              dispatch(viewEmployee());
             }
 const { t: translate } = useTranslation(["employee/viewEmployee"]);
 const employeeColumns = [
    {
     accessorKey: "firstName", //access nested data with dot notation
     header: translate("fname"),
   },
   {
     accessorKey: "lastName", //access nested data with dot notation
     header: translate("lname"),
   },
  // {
  //   accessorKey: "position", //access nested data with dot notation
  //   header: "Position",
  // },
  {
    accessorKey: "email", //access nested data with dot notation
    header: translate("PerEmail"),
  },
];
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Link to="/newEmployee" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
            {translate("add")}
            </Button>
          </Link>
        </Box>
        {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="employeeTitle" headr={header} value={newValue} />}

        {employee?.employee ? (
          <Table
            data={employee?.employee}
            fields={employeeColumns}
            numberOfRows={employee?.employee.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            deleteFunction={handleRemoveEmployee}
            upDatefunction={handleUpdateEmployee}
            viewFunction={openModal}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};

export default AllEmployee;

