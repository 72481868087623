import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaTruck } from "react-icons/fa";
import { FiCalendar, FiMap, FiUser } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../layout/Layout";
import { singleTicketing } from "../../states/actions/ticketingAction";
import { singleBus } from "../../states/actions/busAction";

import { singleBusOwner } from "../../states/actions/busOwnerAction";
import { singleDriver } from "../../states/actions/driverAction";

const SingleBus = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(singleTicketing(id));
  }, []);
  const { tickets } = useSelector((state) => state.tickets);
  // x = tickets?.Route?.cost;
  var cost = parseInt(tickets?.Route?.cost, 10);
  const { buses } = useSelector((state) => state.buses);
  const busOwner = useSelector((state) => state.busOwner);
  const drivers = useSelector((state) => state.drivers);

  console.log("single bus information : ", buses);
  // use effect
  useEffect(() => {
    dispatch(singleBus(id));
  }, []);
  useEffect(() => {
    dispatch(singleBusOwner());
    dispatch(singleDriver());
  }, []);
  const date = new Date();
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Typography variant="h5">Bus details</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ my: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <FiCalendar />
                <Typography variant="h6">Jane 2, 2024</Typography>
              </Box>
            </Box>
            <Box>
              <span
                style={{
                  padding: "3px 10px",
                  color: "#049bf5",
                  backgroundColor: "rgba(4, 155, 245,0.2)",
                  borderRadius: "5px",
                  textDecoration: "none",
                }}
              >
                available
              </span>
            </Box>
          </Box>
          <Divider />
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FaTruck />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Bus Info
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    licensePlate:{" "}
                    <span style={{ opacity: 0.7 }}>{buses?.licensePlate}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Side Number:{" "}
                    <span style={{ opacity: 0.7 }}>{buses?.sideNo}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Talga: <span style={{ opacity: 0.7 }}>{buses?.talga}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Capacity:{" "}
                    <span style={{ opacity: 0.7 }}>{buses?.capacity}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Model: <span style={{ opacity: 0.7 }}>{buses?.model}</span>
                  </Typography>

                  <Link
                    to="/viewTracking"
                    style={{
                      padding: "3px 10px",
                      color: "#049bf5",
                      backgroundColor: "rgba(4, 155, 245,0.2)",
                      borderRadius: "5px",
                      textDecoration: "none",
                    }}
                  >
                    View on Map
                  </Link>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FiUser />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Driver
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Name: <span style={{ opacity: 0.7 }}>dani man</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Phone:{" "}
                    <span style={{ opacity: 0.7 }}>+2519 785 643 56</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Email:{" "}
                    <span style={{ opacity: 0.7 }}>daniman@gmail.com</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FiUser />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Bus Owner
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Name: <span style={{ opacity: 0.7 }}>alemu gebrie</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Phone:{" "}
                    <span style={{ opacity: 0.7 }}>+2519 786 653 24</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Address: <span style={{ opacity: 0.7 }}>bahir dar</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2, opacity: 0.7 }}
                  >
                    4 buses
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Layout>
  );
};

export default SingleBus;
