import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { registerBus } from "../../states/actions/busAction";
import { viewBusOwner } from "../../states/actions/busOwnerAction";
import { viewAssociation } from "../../states/actions/associationAction";
import { viewDriver } from "../../states/actions/driverAction";
import { ToastContainer } from "react-toastify";
import Layout from "../../pages/layout/Layout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
// add new Employee
const NewBus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // use form
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const association = useSelector((state) => state.association);
  const busOwners = useSelector((state) => state.busOwner);
  const drivers = useSelector((state) => state.driver);
  const ownerData=[]
  for (let i=0;i<busOwners?.busOwner?.length;i++){
       ownerData.push(busOwners.busOwner[i])
      }
  // use effect
  useEffect(() => {
    dispatch(viewBusOwner());
    dispatch(viewDriver());
    dispatch(viewAssociation());
  }, []);

  const onSubmit = (data) => {
    const formData = new FormData();
    Object?.entries(data).map((entry) => {
      const [key, value] = entry;
      formData.append(key, value);
    });
    // dispatch
    dispatch(registerBus(data));
    reset();
    setTimeout(function () {
      // navigate("/AllBuses");
    }, 5000);
  };
  const { t: translate } = useTranslation(["bus/addBus"]);
  return (
    <Layout>
      <Box className="py-24 h-screen">
        <div className="mx-auto container max-w-2xl md:w-3/4 shadow-md">
          <div className="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
            <div className="max-w-sm mx-auto md:w-full md:mx-0">
              <div className="inline-flex items-center space-x-4">
                <Typography variant="h6">{translate("header")}</Typography>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className=" space-y-2 shadow pl-3">
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("LPlate")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                      <TextField
                        label={translate("enterLPlate")}
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`licensePlate`, {
                          required: true,
                          maxLength: 200,
                        })}
                      />
                    </Box>
                    {errors.licensePlate && (
                      <p className="text-red-500">
                        {translate("enterLPlate")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("BOwner")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("selectBOwner")}
                        </InputLabel>
                        <Select
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="text"
                          {...register(`busOwnerId`, {
                            required: true,
                          })}
                        >
                          {ownerData?.map((owner) => (
                            <MenuItem
                              key={owner?.busOwnerId}
                              value={owner?.busOwnerId}
                            >
                              {owner?.firstName}
                            </MenuItem>
                          ))} 
                        </Select>
                      </FormControl>
                    </Box>
                    {errors.busOwnerId && (
                      <p className="text-red-500">
                        {" "}
                        {translate("selectBOwner")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("driver")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("selectdriver")}
                        </InputLabel>
                        <Select
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="text"
                          {...register(`driverId`, {
                            required: true,
                          })}
                        >
                          {drivers?.driver?.map((driver) => (
                            <MenuItem key={driver?.id} value={driver?.id}>
                              {driver?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    {errors.driverId && (
                      <p className="text-red-500">
                        {" "}
                        {translate("selectdriver")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("model")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`model`, {
                          required: true,
                          maxLength: 200,
                        })}
                        label={translate("entermodel")}
                      />
                    </Box>
                    {errors.model && (
                      <p className="text-red-500">
                        {" "}
                        {translate("entermodel")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-2 md:space-y-0  w-full p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("capacity")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <div class="w-full inline-flex">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        label={translate("entercapacity")}
                        {...register(`capacity`, {
                          required: true,
                          maxLength: 200,
                        })}
                      />
                    </div>
                    {errors.capacity && (
                      <p className="text-red-500">
                        {" "}
                        {translate("entercapacity")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("sideNo")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <div class="w-full inline-flex ">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register("sideNo", {
                          required: true,
                          maxLength: 200,
                        })}
                        label={translate("entersideNo")}
                      />
                    </div>
                    {errors.sideNo && (
                      <p className="text-red-500">
                        {" "}
                        {translate("entersideNo")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full md:space-y-0 p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("talga")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <div class="w-full inline-flex ">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        label={translate("entertalga")}
                        {...register("talga", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.talga && (
                      <p className="text-red-500">
                        {" "}
                        {translate("entertalga")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("level")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("selectlevel")}
                        </InputLabel>
                        <Select
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="text"
                          {...register(`level`, {
                            required: true,
                            maxLength: 30,
                          })}
                        >
                          <MenuItem value="1"> {translate("l1")}</MenuItem>
                          <MenuItem value="2"> {translate("l2")}</MenuItem>
                          <MenuItem value="3"> {translate("l3")}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {errors.level && (
                      <p className="text-red-500">
                        {" "}
                        {translate("selectlevel")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("price")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register("price", {
                          required: true,
                        })}
                        label={translate("enterprice")}
                      />
                    </Box>
                    {errors.price && (
                      <p className="text-red-500">
                        {" "}
                        {translate("enterprice")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("insurance")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register("insurance", {})}
                        label={translate("enterinsurance")}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-2 text-gray-500 items-center">
                <Typography className="md:w-3/12 max-w-sm mx-auto sans">
                  {translate("association")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box class="w-full inline-flex ">
                     <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                         {translate("selectassociation")}
                       </InputLabel>
                       <Select
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="text"
                          {...register(`associationId`, {
                            required: true,
                          })}
                        >
                          {association?.association?.map((association) => (
                            <MenuItem
                              key={association?.associationId}
                              value={association?.associationId}
                            >
                              {association?.associationName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    {errors.associationId && (
                      <p className="text-red-500">
                        {translate("selectassociation")}!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div class="w-full p-4 text-right text-gray-500">
                <button className="md:w-2/3 focus:outline-none mr-4 bg-blue-400 hover:bg-indigo-500 text-white font-bold xl:text-base md:text-base border border-gray-300 w-full text-sm py-2.5 outline-blue-500 rounded">
                  {translate("button")}
                </button>
              </div>
            </Paper>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default NewBus;
