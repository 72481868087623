import React, { useEffect,useState } from "react";
import { Box, Button,Paper, Typography } from "@mui/material";
import Layout from "../../pages/layout/Layout";
import { useTranslation } from "react-i18next";

const Reference = () => {
    // dispatch
    const { t: translate } = useTranslation(["tarif"]);
        // console.log("All Route: ", routes);
    // use effect
  return (
    <Layout>
    <Box className=" py-24 px-20 h-screen pr-2">
    <div className="w-full overflow-x-auto">
    {/* <ThemeProvider theme={theme}> */}
    <Paper className=" space-y-2 shadow border-2 rounded-md w-full p-5 ">
    <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}>
        <Typography variant="h4" className=" underline">{translate("የቅጣት አይነቶችና ደረጃቸው ዝርዝር ማብራሪያ ")}</Typography>
        </Box>
      <Box className="ml-5">
    <h3 className=" ml-2 mt-1 underline font-semibold">
      1. የአሽከርካሪ ባለሙያን የሚያስጥይቁ
    </h3>
    <h3 className=" ml-5 underline font-semibold">
    የጥፋት፣ አይነት ደረጃና ቅጣት መጠን /ከብር 300 እስከ 3000/
    </h3>
    <h3 className="ml-8 underline font-semibold">
    ደረጃ 1 የጥፋት አይነትና ቅጣት(300)
    </h3>
    <ul className=" pl-12 list-decimal">
      <li>የህዝብ ማመላለሻ ተሽከርካሪ ያለ ህጋዊ ፈቃድ ከስምሪት አገልግሎት ውጭ ማድረግ /መደበቅ/</li>
      <li>የአኦፕሬተርነት ፍቃድ በተሰጠው ውቅት ተሙአልታዋል ተብለው የተገለጹት በቁጥጥር ውቅት አጉአድሎ መገኘት</li>
      <li>በተዘጋጁ ቅጾች መሰረት የሚዘጋጁ መረጃዎች በአግባቡና በወቅቱ ጠብቆ አለማስተላለፍ </li>
    </ul>
    <h3 className="ml-8 underline font-semibold">
    ደረጃ 2 የጥፋት አይነትና ቅጣት(300)
    </h3>
    <ul className=" pl-12 list-decimal">
      <li>የህዝብ ማመላለሻ ተሽከርካሪ ያለ ህጋዊ ፈቃድ ከስምሪት አገልግሎት ውጭ ማድረግ /መደበቅ/</li>
      <li>የአኦፕሬተርነት ፍቃድ በተሰጠው ውቅት ተሙአልታዋል ተብለው የተገለጹት በቁጥጥር ውቅት አጉአድሎ መገኘት</li>
      <li>በተዘጋጁ ቅጾች መሰረት የሚዘጋጁ መረጃዎች በአግባቡና በወቅቱ ጠብቆ አለማስተላለፍ </li>
    </ul>
    <h3 className="ml-8 underline font-semibold">
    ደረጃ 3 የጥፋት አይነትና ቅጣት(300)
    </h3>
    <ul className=" pl-12 list-decimal">
      <li>የህዝብ ማመላለሻ ተሽከርካሪ ያለ ህጋዊ ፈቃድ ከስምሪት አገልግሎት ውጭ ማድረግ /መደበቅ/</li>
      <li>የአኦፕሬተርነት ፍቃድ በተሰጠው ውቅት ተሙአልታዋል ተብለው የተገለጹት በቁጥጥር ውቅት አጉአድሎ መገኘት</li>
      <li>በተዘጋጁ ቅጾች መሰረት የሚዘጋጁ መረጃዎች በአግባቡና በወቅቱ ጠብቆ አለማስተላለፍ </li>
    </ul>
    <h3 className=" ml-2 mt-2 underline font-semibold">
      2. የአሽከርካሪ ረዳትን የሚያስጠቁ
    </h3>
    <h3 className=" ml-5 underline font-semibold">
    የጥፋት ደረጃና ቅጣት መጠን /ከ ብር 50 እስከ 100/
    </h3>
    <h3 className="ml-8 underline font-semibold">
    ደረጃ 1 የጥፋት አይነትና ቅጣት (ብር 50)
    </h3>
    <ul className=" pl-12 list-decimal">
      <li>ተሽከርካሪ ሳይቆም በር መክፈት</li>
      <li>ተሽከርካሪ ሳይቆም ሰው ማውረድ(ማሳፍር)</li>
      <li>የደንብ ልብሱን ባግባቡ አለመልበስ </li>
      <li>ህጋዊ መታዎቂያና ባጅ ይዞ አለመገኘት</li>
    </ul>
    <h3 className="ml-8 underline font-semibold">
    ደረጃ 2 የጥፋት አይነትና ቅጣት(ብር 100)
    </h3>
    <ul className=" pl-12 list-decimal">
      <li>የተሳፋሪዎችን ንብረት ባያያዝ ጉድለት እንዲሰበር ወይም እንዲበላሽ ማድረግ</li>
      <li>መረጃ ሲጠየቅ ለመስጠት ፍቃደኛ አለለመሆን</li>
      <li>ተሳፋሪውን ማንገላታት፤መዘለፍና ማንጉአጠጥ </li>
    </ul>

      <h3 className=" ml-2 mt-2 underline font-semibold">
      3. የተሽከርካሪ ባለንብረትን የሚያስጠይቁ
    </h3>
    <h3 className=" ml-5 underline font-semibold">
    የጥፋት፣ አይነት ደረጃና ቅጣት መጠን /ከብር 300 እስከ 3000/
    </h3>
    <h3 className="ml-8 underline font-semibold">
    ደረጃ 1 የጥፋት አይነትና ቅጣት(300)
    </h3>
    <ul className=" pl-12 list-decimal">
      <li>የህዝብ ማመላለሻ ተሽከርካሪ ያለ ህጋዊ ፈቃድ ከስምሪት አገልግሎት ውጭ ማድረግ /መደበቅ/</li>
      <li>የአኦፕሬተርነት ፍቃድ በተሰጠው ውቅት ተሙአልታዋል ተብለው የተገለጹት በቁጥጥር ውቅት አጉአድሎ መገኘት</li>
      <li>በተዘጋጁ ቅጾች መሰረት የሚዘጋጁ መረጃዎች በአግባቡና በወቅቱ ጠብቆ አለማስተላለፍ </li>
    </ul>
      </Box>
     </Paper>
    </div>
     {/* </ThemeProvider> */}
     </Box>
  </Layout>
  );};
export default Reference;