import { Box, Button, Typography } from "@mui/material";
import React, { useEffect,useState } from "react";
import { FiPlus } from "react-icons/fi";
//import { Link } from "react-router-dom";
import Table from "../../components/Table";
import DetailModal from '../../components/modal/DetailModal';
import { useTranslation } from "react-i18next";
import {
  viewPunishment,
  deletePunishment,
  singlePunishment
} from "../../states/actions/punishmentAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../../pages/layout/Layout";
import Loading from "../loading/Loading";

const ViewPunishment = () => {
  // dispatch
  const dispatch = useDispatch();
  // hooks
  const punishment = useSelector((state) => state.punishment);
  // use effect
  useEffect(() => {
    dispatch(viewPunishment());
  }, []);

  const navigate = useNavigate();
  const handleUpdatepunishment = (data) => {
    navigate("/punishment/" + data.id);
  };
  const handleViewPassenger = (data) => {
    navigate("/singlePunishment/" + data.id);
  };
  function handleRemovepunishment(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deletePunishment(data.id));
    }

    setTimeout(function () {
      window.location.reload();
    }, 500);
  }
    //  Popup data
    const [isOpen, setIsOpen] = useState(false);
    const header= ["description","amount","type","level","punishDate","bus","driver","createdAt","updatedAt"] 
    const [newValue, setNewValue] = useState([]);
    const openModal = async(data) => {
      // const result = await dispatch(singlePunishment(data.id));   
      // const value= [result.payload?.description,result.payload?.amount,result.payload?.type,result.payload?.level,result.payload?.date,result.payload?.Bus?.talga,
      //   result.payload?.Driver?.name,result.payload?.createdAt,result.payload?.updatedAt] 
      // const newArray = [...newValue]; 
      //       newArray[0] = value[0]; 
      //       newArray[1] = value[1]; 
      //       newArray[2] = value[2]; 
      //       newArray[3] = value[3]; 
      //       newArray[4] = value[4]; 
      //       newArray[5] = value[5]; 
      //       newArray[6] = value[6]; 
      //       newArray[7] = value[7]; 
      //       newArray[8] = value[8]; 
      //       setNewValue(newArray);
      //       if(newArray.length>0) {
      //         setIsOpen(true);  
      //         }
        };
        const closeModal = () => {
        setIsOpen(false);
        setNewValue([])
        dispatch(viewPunishment());
        }
    const { t: translate } = useTranslation(["punishment/viewPunishment"]);
     const punishmentColumns = [
          {
            accessorKey: "Bus.talga", //access nested data with dot notation
            header: translate("busTalga"),
          },
          {
            accessorKey: "Driver.name", //access nested data with dot notation
            header: translate("driver"),
          },
          {
            accessorKey: "amount",
            header: translate("Amount"),
          },
          {
            accessorKey: "type", 
            header: translate("type"),
          },
          {
            accessorKey: "level", 
            header: translate("level"),
          },
          {
            accessorKey: "frequency",
            header: translate("Frequency"),
          },
          {
            accessorKey: "date", 
            header: translate("PunishmentDate"),
          },
        ];
        
  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">{translate("header")}</Typography>
          <Link to="/newPunishment" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
              {translate("add")}
            </Button>
          </Link>
        </Box>
        {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="Punishment Detail Information" headr={header} value={newValue} />}
        <Link to="/reference" style={{ textDecoration: "none" }}>
            <Button
              sx={{ borderRadius: "5px" }}
            >
            {translate("punishmentRefer")}
            </Button>
          </Link>
        {punishment?.punishment ? (
          <Table
            data={punishment?.punishment}
            fields={punishmentColumns}
            numberOfRows={punishment?.punishment?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview={true}
            //  routeLink="products"
            deleteFunction={handleRemovepunishment}
            upDatefunction={handleUpdatepunishment}
            viewFunction={handleViewPassenger}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default ViewPunishment;

