import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/index.css";
import App from "./App";
import { ThemeToggleProvider } from "./contexts/ThemeContext";
import { CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import store from "./config/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./localization/i18";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeToggleProvider>
    <CssBaseline />
    <Router>
      <Suspense
        fallback={
          <div className="mt-10 text-3xl font-bold text-center">Loading...</div>
        }
      >
        {" "}
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <App />
          </Provider>
        </I18nextProvider>
      </Suspense>
    </Router>
  </ThemeToggleProvider>
  // </React.StrictMode>
);
