export const SalaryData= [
    {
        id: 1,
        name: 'Gashu',
      salary:"12000",
      month:"December"
    },
    {
        id: 1,
        name: 'Gashu',
        salary:"2000",
        month:"October"

      },
      {
        id: 1,
        name: 'Gashu',
        salary:"20000",
        month:"September",
      },
      {
        id: 1,
        name: 'Gashu',
        salary:"10000",
        month:"Ogest"
      },
  ];