import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { viewDriver } from "../../states/actions/driverAction";
import {
  updateAccident,
  singleAccident,
} from "../../states/actions/accidentAction";
import { useParams } from "react-router-dom";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { viewBus } from "../../states/actions/busAction";
import Layout from "../../pages/layout/Layout";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const UpdateAccident = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    onReset();
  }, []);

  const onReset = async () => {
    const result = await dispatch(singleAccident(id));
    reset(result.payload);
  };

  // use form
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = (accidentData) => {
    // pass the data form data
    const formData = new FormData();
    Object.entries(accidentData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const selectedTime = new Date(accidentData.accidentDate).toLocaleTimeString(
      [],
      {
        hour: "2-digit",
        minute: "2-digit",
      }
    );
    const newData = {
      ...accidentData,
      accidentDate: selectedTime,
    };
    dispatch(updateAccident(newData));
  };
  const buses = useSelector((state) => state.buses);
  const driver = useSelector((state) => state.driver);
  // use effect
  useEffect(() => {
    dispatch(viewBus());
    dispatch(viewDriver());
  }, []);
  const { t: translate } = useTranslation(["accident"]);
  return (
    <Layout>
      <Box class="py-24 h-screen">
        <div class="mx-auto container max-w-2xl md:w-3/4 shadow-md">
          <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-indigo-400 rounded-t">
            <div class="max-w-sm mx-auto md:w-full md:mx-0">
              <div class="inline-flex items-center space-x-4">
                <Typography variant="h6">
                  {translate("UpdateAccidentData")}
                </Typography>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className=" shadow">
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  {translate("busTalga")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("selectTalga")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={buses?.id}
                          {...register(`busId`, {
                            maxLength: 7,
                          })}
                        >
                          {buses?.buses?.map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.talga}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  {translate("driverPhone")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {translate("selectDriver")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={driver?.id}
                          {...register(`driverId`, {
                            maxLength: 70,
                          })}
                        >
                          {driver?.driver?.map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.contactNumber}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
              </div>
              <Divider />
              <div class="md:inline-flex  space-y-4 md:space-y-0  w-full p-4 text-gray-500 items-center">
                <Typography class="md:w-4/12 mx-auto max-w-sm">
                  {translate("place")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`place`, {
                          maxLength: 20,
                        })}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-6 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  {translate("accidentType")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <FormControl fullWidth size="small">
                        {/* <InputLabel id="demo-simple-select-label">
                        Select Type
                      </InputLabel> */}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value="easy"
                          {...register(`type`, {
                            maxLength: 700,
                          })}
                        >
                          <MenuItem value="easy">{translate("acc1")}</MenuItem>
                          <MenuItem value="easy">{translate("acc2")}</MenuItem>
                          <MenuItem value="easy">
                            {translate("acc3")}
                          </MenuItem>{" "}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full space-y-2 md:space-y-0 p-8 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  {translate("AccidentDate")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        {...register(`accidentDate`, {
                          maxLength: 100,
                        })}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <Divider />

              <div class="md:inline-flex w-full space-y-1 md:space-y-0 p-4 text-gray-500 items-center">
                <Typography class="md:w-4/12 max-w-sm mx-auto">
                  {translate("Description")}
                </Typography>
                <div class="md:w-2/3 mx-auto max-w-sm space-y-5">
                  <div>
                    <Box>
                      <TextField
                        variant="outlined"
                        rows={2}
                        fullWidth
                        multiline
                        type="text"
                        {...register(`description`, {
                          maxLength: 700,
                        })}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <div class="w-full pb-2 text-right text-gray-500 item-center">
                <button className="w-full md:w-2/3 focus:outline-none mr-4 bg-blue-400 hover:bg-indigo-500 text-white font-bold xl:text-base md:text-base border border-gray-300 w-full text-sm py-2.5 outline-blue-500 rounded">
                  {translate("button2")}
                </button>
              </div>
            </Paper>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Layout>
  );
};
export default UpdateAccident;
