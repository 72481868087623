import React, { useEffect,useState } from "react";
import { FiPlus } from "react-icons/fi";
import Loading from "../loading/Loading";
import Table from "../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { viewPayment, deletePayment,singlePayment} from "../../states/actions/paymentAction";
import Layout from "../../pages/layout/Layout";
import { useNavigate, Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import DetailModal from '../../components/modal/DetailModal';
import { useTranslation } from "react-i18next";

// Payment List component
const ViewPayment = () => {
  const navigate = useNavigate();
  const handleUpdatePayment = (data) => {
    navigate("/payment/" + data.id);
  };
  // use effect
  useEffect(() => {
    dispatch(viewPayment());
  }, []);

  const dispatch = useDispatch();
  const payments = useSelector((state) => state.payments);
  function handleRemovePayment(data) {
    if (
      window.confirm(
        "Data deleted permantly. Are you sure to delete completely!"
      )
    ) {
      dispatch(deletePayment(data.id));
    }
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }
    //  Popup data
    const [isOpen, setIsOpen] = useState(false);
    const header= ["driver","associationName","station","paymentDate","amount"] 
    const [newValue, setNewValue] = useState([]);
    const openModal = async(data) => {
      const result = await dispatch(singlePayment(data.id));
      const value= [result.payload?.Driver.name,result.payload?.association.associationName,result.payload?.Station.stationName,result.payload?.paymentDate,result.payload?.amount] 
      const newArray = [...newValue]; 
            newArray[0] = value[0]; 
            newArray[1] = value[1]; 
            newArray[2] = value[2]; 
            newArray[3] = value[3]; 
            newArray[4] = value[4]; 
        setNewValue(newArray);
        if(newArray.length>0) {
          setIsOpen(true);  
          }
       };
    const closeModal = () => {
       setIsOpen(false);
       setNewValue([])
       dispatch(viewPayment());
      };
      const { t: translate } = useTranslation(["payment/viewPayment"]);
      const paymentColumns = [
        {
          accessorKey: "Driver.name", //access nested data with dot notation
          header: translate("driver"),
        },
        {
          accessorKey: "association.associationName", //access nested data with dot notation
          // accessorKey: "Driver.name", //access nested data with dot notation
          header: translate("association"),
        },
        {
          accessorKey: "Station.stationName", //access nested data with dot notation
          //  accessorKey: "Driver.name", //access nested data with dot notation
          header: translate("station"),
        },
        {
          accessorKey: "paymentDate",
          header: translate("date"),
        },
        {
          accessorKey: "amount",
          header: translate("amount"),
        },
      ];

  return (
    <Layout>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">{translate("header")}</Typography>
          <Link to="/newPayment" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "5px" }}
            >
              {translate("add")}
            </Button>
          </Link>
        </Box>
        {isOpen && <DetailModal open={isOpen} closeModal={closeModal} title="paymentTitle" headr={header} value={newValue} />}

        <Box sx={{ mt: 2 }}>
          {payments?.payments ? (
            <Table
              data={payments?.payments}
              fields={paymentColumns}
              numberOfRows={payments?.payments?.length}
              enableTopToolBar={true}
              enableBottomToolBar={true}
              enablePagination={true}
              enableRowSelection={false}
              enableColumnFilters={false}
              enableEditing={true}
              enableColumnDragging={false}
              showPreview={true}
              deleteFunction={handleRemovePayment}
              upDatefunction={handleUpdatePayment}
              viewFunction={openModal}
            />
          ) : (
            <Loading />
          )}
        </Box>
      </Box>
    </Layout>
  );
};
export default ViewPayment;
